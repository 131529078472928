import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { app, db } from "../firebase.config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { faPlusCircle, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import "../css/SignUp.css";
import profanityList from "../data/profanityList.js";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Footer from "../components/Footer";

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const { email, password, firstName, lastName, confirmPassword, phoneNumber } =
    formData;

  const [showSpinner, setShowSpinner] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth(app);

  // Sign Up User
  const createUserAccount = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    if (
      profanityList.some((v) => firstName.toLowerCase().includes(v)) ||
      profanityList.some((x) => lastName.toLowerCase().includes(x))
    ) {
      toast.error("Profanity detected in name, please choose another.", {
        autoClose: 2000,
      });
      return;
    }

    if (
      isEmail(email) &&
      firstName.length > 1 &&
      lastName.length > 1 &&
      password === confirmPassword &&
      phoneNumber.length < 14 &&
      phoneNumber.length > 6
    ) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        updateProfile(auth.currentUser, {
          displayName: `${firstName} ${lastName}`,
        });

        const formDataCopy = { ...formData };
        delete formDataCopy.password;
        delete formDataCopy.confirmPassword;

        // // Submitting user information to DB
        // await setDoc(doc(db, "users", user.uid), {
        //   firstName: formDataCopy.firstName,
        //   lastName: formDataCopy.lastName,
        //   email: formDataCopy.email,
        //   phoneNumber: formDataCopy.phoneNumber,
        //   signUpTimestamp: serverTimestamp(),
        // });

        const body = JSON.stringify({
          userUID: user.uid,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
        });

        console.log("before user sign up");
        // Run firebase cloud funtion to store user details in firestore users DB
        await fetch(
          "https://storeuserdetails-storeuserdetails-ybv5mc22oq-ts.a.run.app",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body,
          }
        );

        console.log("user signed up");
        setShowSpinner(false);
        toast.success("Thanks for signing up!", { autoClose: 2000 });

        navigate("/");
      } catch (error) {
        toast.error(error);
        setShowSpinner(false);
      }
    } else {
      toast.error("Something went wrong - check form items.", {
        autoClose: 2000,
      });
      setShowSpinner(false);
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  // function to check if email is correct email format
  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const windowWidth = window.screen.width;

  // function to check everything - then set button to be enabled
  // const allChecksPass = (name, email, password, confirmPassword) => {
  //   if(name.length > 5 && name.length < 13 )
  // }

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          SIGN | <span className="font-normal">UP</span>
        </p>
        {/* <h1 className="text-3xl font-semibold text-gray-900 text-center mt-5 mb-4">
        Register Here
      </h1> */}
        {/* Logon Card */}
        <div className="flex justify-center mt-4">
          <div className="border-2 rounded-2xl border-gray-300/80 py-3 px-4 shadow-inner shadow-md bg-gray-50 mb-5">
            {/* Card icon */}
            <div className="text-center">
              <FontAwesomeIcon
                icon={faUserCircle}
                color="black"
                size={windowWidth < 400 ? "2x" : "3x"}
                className="mb-2"
              />

              {/* Heading */}
              <p className="mt-2 text-base sm:text-lg font-semibold">Sign Up</p>
              {/* <p className="mt-2 text-xs font-sem">
                Contact details required to contact our winners
              </p> */}
            </div>

            {/* Logon Fields */}
            <div className="flex">
              <form onSubmit={createUserAccount}>
                <div className="flex flex-col py-2 w-72 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    First Name
                  </label>
                  <input
                    className={
                      firstName && firstName.length > 1
                        ? "accepted p-2 text-sm sm:text-base"
                        : "p-2 text-sm sm:text-base"
                    }
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={onChange}
                    placeholder="First Name"
                  ></input>
                </div>
                <div className="flex flex-col py-2 w-72 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    Surname/Last name
                  </label>
                  <input
                    className={
                      lastName && lastName.length > 1
                        ? "accepted p-2 text-sm sm:text-base"
                        : "p-2 text-sm sm:text-base"
                    }
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={onChange}
                    placeholder="Surname/Last name"
                  ></input>
                </div>
                <div className="flex flex-col py-2 w-72 mb-2">
                  <label className="text-sm font-semibold mb-1">Email</label>
                  <input
                    className={
                      isEmail(email)
                        ? "accepted p-2 text-sm sm:text-base"
                        : "p-2 text-sm sm:text-base"
                    }
                    type="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Enter email"
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-3">
                  <label className="text-sm font-semibold mb-1">Password</label>
                  <input
                    className={
                      password === confirmPassword && password.length > 6
                        ? "accepted p-2 text-sm sm:text-base"
                        : "p-2 text-sm sm:text-base"
                    }
                    type="password"
                    id="password"
                    placeholder="Minimum 6 characters..."
                    value={password}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-3">
                  <label className="text-sm font-semibold mb-1">
                    Confirm Password
                  </label>
                  <input
                    className={
                      password === confirmPassword && confirmPassword.length > 0
                        ? "accepted p-2 text-sm sm:text-base"
                        : "p-2 text-sm sm:text-base"
                    }
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-3">
                  <label className="text-sm font-semibold mb-1">
                    Phone Number
                  </label>
                  <input
                    className={
                      phoneNumber.length < 14 && phoneNumber.length > 6
                        ? "accepted p-2 text-sm sm:text-base mb-1"
                        : "p-2 text-sm sm:text-base  mb-1"
                    }
                    type="text"
                    id="phoneNumber"
                    placeholder="No area code required"
                    value={phoneNumber}
                    onChange={onChange}
                  ></input>
                </div>

                {showSpinner ? (
                  <div className="flex mt-5 mb-4 justify-center">
                    <BeatLoader color="#36D7B7" />
                  </div>
                ) : (
                  // Sign Up button
                  <div className="text-center rounded-md py-2 mt-4 border-2 border-gray-300 duration-300 hover:bg-gray-300 hover:border-gray-400 hover:cursor-pointer mb-4">
                    <button className="text-base">
                      Sign Up{" "}
                      <FontAwesomeIcon icon={faPlusCircle} color="grey" />
                    </button>
                  </div>
                )}

                {/* <OAuth /> */}

                {/* Sign in Instead */}
                <div
                  onClick={() => navigate("/sign-in")}
                  className="text-center border-gray-300 duration-300 hover:font-semibold hover:cursor-pointer"
                >
                  <button className="text-base text-teal-400">
                    Sign In Instead{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default SignUp;
