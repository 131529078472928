import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const ShoppingCartContext = createContext({});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);

  const getNumberOfEntriesInCart = (competitionID) => {
    return cartItems
      .filter((item) => item.competitionID === competitionID)
      .reduce((total, item) => total + item.numberOfEntries, 0);
  };

  const addToCart = (
    competitionID,
    competitionTitle,
    numberOfEntries,
    packagePrice,
    packageLevel
    //competitionTitle,
    // competitionTotalTickets,
    // maxNumberOfEntries,
    // competitionImageURL,
  ) => {
    const newItem = {
      id: uuidv4(),
      competitionID,
      competitionTitle,
      numberOfEntries,
      packagePrice,
      packageLevel,
      //competitionTitle,
      // competitionTotalTickets,
      // maxNumberOfEntries,
      // competitionImageURL,
    };

    setCartItems((currentItems) => [...currentItems, newItem]);
    toast.success("Package added to cart!", {
      autoClose: 1000,
    });
  };

  const removeFromCart = (id) => {
    setCartItems((currentItems) => {
      return currentItems.filter((item) => item.id !== id);
    });

    toast.info("Package removed from cart", {
      autoClose: 1000,
    });
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        addToCart,
        removeFromCart,
        clearCart,
        getNumberOfEntriesInCart,
        cartItems,
        setCartItems,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
