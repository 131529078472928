import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  orderBy,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";
import { db, app } from "../firebase.config";

const useFetchCompetitions = () => {
  const [competitions, setCompetitions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCompetitions = async () => {
    try {
      // Get reference
      const competitionsRef = collection(db, "competitions");

      // Create query
      const q = query(
        competitionsRef,
        where("isActive", "==", true),
        orderBy("timestamp", "desc")
      );

      // Execute query
      const querySnap = await getDocs(q);

      const competitionsArray = [];

      querySnap.forEach((doc) => {
        competitionsArray.push({
          data: doc.data(),
          id: doc.id,
        });
      });

      // Get numberOfTicketsRemaining from ticketsDocument
      for (let i = 0; i < competitionsArray.length; i++) {
        const compRef = doc(
          db,
          "competitions",
          competitionsArray[i].id,
          "ticketsCollection",
          "ticketsDocument"
        );

        // Add numberOfTicketsRemaining to the competitionsArray
        const compSnap = await getDoc(compRef);
        if (compSnap.exists()) {
          competitionsArray[i] = {
            ...competitionsArray[i],
            numberOfTicketsRemaining: compSnap.data().numberOfTicketsRemaining,
          };
        }
      }

      setCompetitions(competitionsArray);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCompetitions();
  }, []);

  return { competitions, isLoading };
};

// const useFetchCompetitions = () => {
//   const [competitions, setCompetitions] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const fetchCompetitions = async () => {
//     try {
//       const competitionsRef = collection(db, "competitions");
//       const q = query(
//         competitionsRef,
//         where("isActive", "==", true),
//         orderBy("timestamp", "desc")
//       );

//       const querySnap = await getDocs(q);

//       querySnap.docs.map(async (doc) => {
//         const competitionData = doc.data();
//         const competitionId = doc.id;

//         const compRef = doc(
//           db,
//           "competitions",
//           competitionId,
//           "ticketsCollection",
//           "ticketsDocument"
//         );

//         const compSnap = await getDoc(compRef);
//         if (compSnap.exists()) {
//           const competition = {
//             data: competitionData,
//             id: competitionId,
//             numberOfTicketsRemaining: compSnap.data().numberOfTicketsRemaining,
//           };

//           setCompetitions((prevCompetitions) => [
//             ...prevCompetitions,
//             competition,
//           ]);
//         }
//       });

//       setIsLoading(false);
//     } catch (error) {
//       console.log(error);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCompetitions();
//   }, []);

//   return { competitions, isLoading };
// };

export default useFetchCompetitions;
