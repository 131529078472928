import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import profanityList from "../data/profanityList.js";

const NewsLetterSignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const windowWidth = window.innerWidth;

  // function to find if email is correct email format
  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const joinNewsLetter = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for profanity in first or last name
    if (
      profanityList.some((v) => firstName.toLowerCase().includes(v)) ||
      profanityList.some((x) => lastName.toLowerCase().includes(x))
    ) {
      toast.error(
        "Profanity detected in first or last name, please choose another.",
        {
          autoClose: 2000,
        }
      );
      setFirstName("");
      setLastName("");
      setLoading(false);
      return;
    }

    // First - check if email is in correct format and first/last name has been entered
    if (isEmail(email) && firstName.length > 1 && lastName.length > 1) {
      const body = JSON.stringify({
        firstName,
        lastName,
        email,
      });

      // If so, POST to joinMailingList HTTP Cloud Function
      try {
        // Send a POST request to your function
        const response = await fetch(
          `https://joinmailinglist-joinmailinglist-ybv5mc22oq-ts.a.run.app`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body,
          }
        );

        // Parse the response body
        const data = await response.json();

        if (data.error) {
          console.log(data.error);
          throw new Error(data.error);
        }
        setLoading(false);
        toast.success(
          "Thanks for signing up. Keep an eye out for the monthly free competitions.",
          { autoClose: 2000 }
        );
        setFirstName("");
        setLastName("");
        setEmail("");
      } catch (error) {
        console.log(error);
        toast.error(error.message, {
          autoClose: 2000,
        });
        setLoading(false);
      }
    }
    // Not in correct email format
    else {
      toast.error("Oops, something is wrong with the name or email.", {
        autoClose: 2000,
      });
      setLoading(false);
    }
  };

  return (
    // <div className="flex-container bg-gray-100 py-4">
    <div className="flex flex-wrap justify-center align-center py-2 sm:py-4 bg-gray-100 htp-background-image pt-3">
      {/* Left Column */}
      <div className="mx-3">
        {windowWidth < 800 ? (
          <></>
        ) : (
          <div>
            <h3 className="text-base sm:text-lg">
              <span className="font-bold text-base sm:text-xl text-teal-500 tracking-wide">
                Free
              </span>{" "}
              entry to our{" "}
              <span className="font-bold text-base sm:text-xl text-teal-500 tracking-wide">
                $500
              </span>{" "}
              monthly draw
            </h3>
          </div>
        )}
        <h3 className="text-base font-semibold">
          Sign up to our members list <FontAwesomeIcon icon={faArrowRight} />
        </h3>
      </div>

      {/* Right column */}
      <div className="mx-3">
        <form onSubmit={joinNewsLetter}>
          <div className="flex flex-col sm:flex-row py-2 w-full justify-center">
            <input
              type="text"
              value={firstName}
              id="first_name"
              placeholder="First Name"
              className={
                windowWidth < 600
                  ? "text-xs sm:text-sm mb-1"
                  : "text-xs sm:text-sm mr-1"
              }
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
            <input
              type="text"
              className="text-xs sm:text-sm"
              value={lastName}
              id="last_name"
              placeholder="Surname"
              onChange={(e) => setLastName(e.target.value)}
            ></input>
          </div>
          <div className="flex-container">
            <div className="flex flex-col sm:py-2 w-full mb-2">
              <input
                className="text-xs sm:text-sm"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
              ></input>
            </div>
            {loading ? (
              <div className="flex my-1 justify-center align-center items-center">
                <BeatLoader size={18} color="#5eead4" />
              </div>
            ) : (
              <div className="my-1 font-semibold text-white py-2 px-3 border-2 rounded-lg border-black-400/80 shadow-inner shadow-xs bg-gradient-to-r from-green-300 to-teal-600 shadow-lg shadow-cyan-500/50 transition duration-500 hover:border-teal-300 hover:font-bold ">
                <button
                  className="text-sm sm:text-base mx-3"
                  onClick={joinNewsLetter}
                >
                  Join Now
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsLetterSignUp;
