import React, { useEffect, useState } from "react";
import HowItWorks from "../components/HowItWorks";
import useFetchCompetitions from "../hooks/useFetchCompetitions";
import ActiveCompetitionCard from "../components/ActiveCompetitionCard";
import Footer from "../components/Footer";
import NewsLetterSignUp from "../components/NewsLetterSignUp";
import SCHF from "../assets/SCHF.svg";
import bannerImageURL from "../assets/banner2.jpg";
import Banner from "../components/Banner";
import FAQ from "../components/FAQ";

const Home = () => {
  //const [isLoading, setIsLoading] = useState(true);
  const { isLoading, competitions } = useFetchCompetitions();

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* Navbar */}
        {/* <NavBar /> */}
        {/* Sydney Children's Hospitals Foundation Banner */}
        <div className="flex items-center gap-3 justify-center align-center shadow-md py-3 htp-background-image">
          <p className="font-semibold text-xs sm:text-sm mt-3">
            Proud partners of
          </p>
          <img src={SCHF} width="150" alt="SCHF" />
        </div>

        <Banner />

        {/* Sign up to our news letter */}
        <NewsLetterSignUp />

        {/* Active Competitions */}
        <div className="mb-4 sm:mb-0 bg-white shadow-md mx-auto">
          <h1
            className={
              window.innerWidth < 800
                ? "text-lg py-3 font-semibold text-gray-900 text-center"
                : "text-lg py-3 font-semibold text-gray-900 text-center tracking-wider"
            }
          >
            ACTIVE | <span className="font-normal">COMPETITIONS</span>
          </h1>
        </div>
        {/* <div className="flex-container"> */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {competitions &&
            competitions.map((competition, index) => {
              return (
                <ActiveCompetitionCard
                  competition={competition}
                  isLoading={isLoading}
                  key={index}
                />
              );
            })}
        </div>
        {/* How to play */}
        <HowItWorks />
      </div>

      <FAQ />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
