import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CharityCarousel from "../components/CharityCarousel";
import ImageSlider from "../components/ImageSlider";
import SCHF from "../assets/SCHF.svg";
import kidsResearch from "../assets/kidsResearch.png";
import sydneyChildrensHospitalRandwick from "../assets/sydneyChildrensHospitalRandwick.png";
import bearCottage from "../assets/bearCottage.png";
import childrensHospitalWestmead from "../assets/childrensHospitalWestmead.png";
import nets from "../assets/nets.png";

const Charity = () => {
  const imageURLs = [
    "https://dkmpk7k3ppdsy.cloudfront.net/fa96223f7de2f12b1c47f241da2f23b8.png",
    "https://d1h79zlghft2zs.cloudfront.net/uploads/2019/07/86-768x378.png",
    "https://static.ethicaljobs.com.au/media/social/1561701926_wc82t_.jpeg",
  ];

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        {/* Heading */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          CHARITY | <span className="font-normal">PARTNERSHIP</span>
        </p>

        {/* Main */}
        <div>
          {/* Paragraph on Charity work */}
          <div className="flex justify-center w-full">
            <div className="px-3 sm:w-4/5 xl:w-3/5 sm:mb-2 sm:mt-2">
              <p className="text-sm sm:text-base text-center">
                {" "}
                At Lift Competitions, we are incredibly proud to have partnered
                with{" "}
                <span className="font-semibold">
                  Sydney Children's Hospitals Foundation.
                </span>
              </p>
              <div className="flex justify-center">
                {/* <CharityCarousel /> */}
                {/* <ImageSlider images={imageURLs} /> */}
                <img
                  src={SCHF}
                  className="w-7/12 md:w-4/12 2xl:w-2/12 mb-4"
                  alt="SCHFCharityImage"
                />
              </div>
              <p className="font-semibold text-base sm:text-lg">
                Where your donation goes
              </p>
              <p className="text-sm sm:text-base">
                The Sydney Children's Hospitals Foundation exists to promote
                charitable investment in the prevention and management of
                childhood illness, with a focus on kids and families receiving
                treatment or care in services provided by Sydney Children's
                Hospitals Network. Nowhere else can you make one donation and
                help different kids of all ages, all illnesses and all
                conditions. Our (and your) donations help the SCHF harness the
                power of collective giving to fund the highest and most urgent
                priorities to have the greatest impact on children’s health, now
                and in the future.
              </p>
              <p className="text-sm sm:text-base">
                The Sydney Children's Hospitals Foundation provides assistance
                and donations to the following charitable entities:
              </p>
            </div>
          </div>

          {/* Charity images */}
          <div className="flex-col md:flex-row justify-center gap-4 px-3 sm:px-5">
            <div className="flex w-full justify-center">
              <div className="flex flex-col md:flex-row xl:w-8/12 items-center sm:gap-3 md:px-5">
                <img
                  src={kidsResearch}
                  alt="kidsResearch"
                  className="w-7/12 md:w-3/12 2xl:w-2/12 mt-1 sm:my-4"
                />
                <p className="text-sm sm:text-base">
                  The Kids Research is the research division of Sydney
                  Children’s Hospitals Network. With over 600 researchers,
                  support staff and students, Kids Research is dedicated to
                  discovering new ways to improve the health of children. It
                  leads research into a wide range of childhood diseases,
                  resulting in significant advances in the treatment of cancer,
                  obesity, kidney, heart and respiratory illnesses, diabetes and
                  muscular dystrophy.{" "}
                </p>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <div className="flex flex-col md:flex-row xl:w-8/12 items-center sm:gap-3 md:px-5">
                <img
                  src={sydneyChildrensHospitalRandwick}
                  alt="sydneyChildrensHospitalRandwick"
                  className="w-7/12 md:w-3/12 2xl:w-2/12 my-3 sm:my-4"
                />
                <p className="text-sm sm:text-base">
                  Sydney Children’s Hospital, Randwick is a world-leading
                  hospital providing provides a complex and comprehensive range
                  of services across paediatric and adolescent medicine and
                  surgery, caring for more than 74,777 critically ill and
                  injured children each year. The hospital treats children with
                  a multitude of conditions, including cancer, trauma, HIV/AIDS,
                  congenital abnormalities, disabilities, heart disease and
                  respiratory disorders.{" "}
                </p>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <div className="flex flex-col md:flex-row xl:w-8/12  items-center sm:gap-3 md:px-5">
                <img
                  src={bearCottage}
                  alt="bearCottage"
                  className="w-7/12 md:w-3/12 2xl:w-2/12 my-3 sm:my-4"
                />
                <p className="text-sm sm:text-base">
                  Bear Cottage is the only children’s hospice in NSW providing
                  respite and end of life care for children with life-limiting
                  conditions and their families. It is one of only three such
                  hospices in Australia, and the only one in the world
                  affiliated with a children’s hospital.{" "}
                </p>
              </div>
            </div>
            <div className="flex w-full justify-center">
              <div className="flex flex-col md:flex-row xl:w-8/12 items-center sm:gap-3 md:px-5">
                <img
                  src={childrensHospitalWestmead}
                  alt="childrensHospitalWestmead"
                  className="w-7/12 md:w-3/12 2xl:w-2/12 mt-3 sm:my-4"
                />
                <p className="text-sm sm:text-base">
                  The Children’s Hospital at Westmead cares for more than 99,164
                  children every year. As the largest paediatric centre in NSW,
                  the Hospital provides world-class care to children from NSW,
                  Australia and across the Pacific Rim. It is home to the NSW
                  Paediatric Burns Unit, the Paediatric Liver Transplant Unit,
                  the National Centre for Immunisation Research and
                  Surveillance, the NSW Newborn Screening Service and National
                  Poisons Information Centre, as well as the first Paediatric
                  Tumour Bank in the southern hemisphere.{" "}
                </p>
              </div>
            </div>

            <div className="flex w-full justify-center ">
              <div className="flex flex-col md:flex-row xl:w-8/12 items-center sm:gap-3 md:px-5">
                <img
                  src={nets}
                  alt="nets"
                  className="w-7/12 md:w-3/12 2xl:w-2/12 mt-3 sm:my-4"
                />
                <p className="text-sm sm:text-base">
                  Operating 24 hours a day, 7 days a week, The Newborn and
                  paediatric Emergency Transport Service (NETS) is a statewide
                  service providing expert clinical advice, clinical
                  coordination, emergency treatment and stabilisation and
                  inter-hospital transport for very sick babies and children up
                  to the age of 16 years. It is the only service of its kind in
                  Australia.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Charity;
