import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuthStatus from "../hooks/useAuthStatus";
import { BeatLoader } from "react-spinners";

const PrivateRoute = () => {
  const { isLoggedIn, isLoading } = useAuthStatus();
  if (isLoading) {
    return (
      <div className="flex mt-5 justify-center">
        <BeatLoader color="#36D7B7" />
      </div>
    );
  }

  return isLoggedIn ? <Outlet /> : <Navigate to={"/sign-in"} />;
};

export default PrivateRoute;
