import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { BeatLoader } from "react-spinners";
import { getDoc, doc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useShoppingCart } from "../context/useShoppingCart";
import { toast } from "react-toastify";

const packageBenefits = {
  bronze: {
    entries: 1,
    benefits: "Lift Rewards: 30 days access",
    color: "#cd7f32",
    shadow: "shadow-[#cd7f32]/50",
  },
  silver: {
    entries: 3,
    benefits: "Lift Rewards: 30 days access",
    color: "#c0c0c0",
    shadow: "shadow-[#c0c0c0]/50",
  },
  gold: {
    entries: 5,
    benefits: "Lift Rewards: 30 days access",
    color: "#ffd700",
    shadow: "shadow-[#ffd700]/50",
  },
};

const CartPackage = ({
  id,
  competitionID,
  numberOfEntries,
  packageLevel,
  packagePrice,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [competition, setCompetition] = useState({});
  const { removeFromCart } = useShoppingCart();

  useEffect(() => {
    const fetchCompetition = async () => {
      const docRef = doc(db, "competitions", competitionID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompetition(docSnap.data());
        setIsLoading(false);
      }
    };
    fetchCompetition();
  }, [competitionID]);

  const packageColor = packageBenefits[packageLevel].color;
  const packageShadow = packageBenefits[packageLevel].shadow;

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center align-center items-center">
          <BeatLoader color="#14b8a6" />
        </div>
      ) : (
        <div
          className={`flex flex-row gap-1 mb-3 p-2 border-b-2 shadow-md ${packageShadow} border-${packageColor}`}
        >
          <div className="basis-5/12 sm:basis-3/12 mr-3">
            <img
              src={competition.imageURLs[0]}
              alt={competition.competitionTitle}
              className="w-full sm:w-48 rounded-md"
            />
          </div>
          <div className="basis-7/12 sm:basis-9/12">
            <div className="flex justify-between mb-3">
              <div
                className={`text-xs sm:text-base font-semibold border-b-2 border-gray-300/100 py-1 w-10/12`}
              >
                {packageBenefits[packageLevel].benefits}
                <span className="block mt-1">
                  Competition: {competition.competitionTitle}
                </span>
              </div>
              <FontAwesomeIcon
                icon={faX}
                className="cursor-pointer"
                onClick={() => removeFromCart(id)}
              />
            </div>
            {/* <div className="my-1 text-sm sm:text-base">
              <span className="font-semibold">Package Price:</span> $
              {competition.priceOfTicket.toFixed(2)}
            </div> */}
            <div className="my-1 text-sm sm:text-base">
              <span className="font-semibold">Package Level:</span>{" "}
              <span
                style={{
                  color: packageColor,
                  fontWeight: "bold",
                  textShadow: "inherit",
                }}
              >
                {packageLevel.charAt(0).toUpperCase() + packageLevel.slice(1)}
              </span>
            </div>
            {/* <div className="my-1 text-sm sm:text-base">
              <span className="font-semibold">Giveaway Entries:</span>{" "}
              {packageBenefits[packageLevel].entries}
            </div> */}
            <div className="flex justify-between">
              <div className="my-1 text-sm sm:text-base">
                <span className="font-semibold">Total Entries: </span>{" "}
                {numberOfEntries}
              </div>
              <div className="mt-1 text-sm sm:text-base">
                <span className="font-semibold">Total Price: </span> $
                {packagePrice}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartPackage;
