import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/PurchaseHistoryTable.css";
import { format } from "date-fns";
import PreviousOrder from "./PreviousOrder";

const PurchaseHistory = ({ previousOrders }) => {
  const [showPreviousOrderList, setShowPreviousOrderList] = useState(true);
  const [previousPurchaseOrderNumber, setPreviousPurchaseOrderNumber] =
    useState("");
  const [previousOrderCompetitionIDs, setPreviousOrderCompetitionIDs] =
    useState("");

  const [formattedPreviousOrders, setFormattedPreviousOrders] = useState([]);

  // Array to return unique array - used for returning unique uuid list
  const returnUniqueArray = (arr) => {
    return Array.from(new Set(arr));
  };

  // helper function to for date sorting from strings
  const convertStringToDate = (dateString) => {
    var dataSplit = dateString.split("/");
    var dateConverted;

    if (dataSplit[2].split(" ").length > 1) {
      var hora = dataSplit[2].split(" ")[1].split(":");
      dataSplit[2] = dataSplit[2].split(" ")[0];
      dateConverted = new Date(
        dataSplit[2],
        dataSplit[1] - 1,
        dataSplit[0],
        hora[0],
        hora[1]
      );
    } else {
      dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
    }

    return dateConverted;
  };

  useEffect(() => {
    let formattedData = [];
    let orderNumberList = [];
    let competitionIDs = [];

    // Get list of all orderNumbers from previous orders
    previousOrders.forEach((order) => orderNumberList.push(order.orderNumber));

    // Get unique uuid list to find unique order numbers
    let uniqueOrderID = returnUniqueArray(orderNumberList);

    // Filter previous orders to show unique orders (found: https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects)
    let filteredOrders = previousOrders
      .filter(
        (order, index, self) =>
          self.findIndex(
            (order2) => order2.orderNumber === order.orderNumber
          ) === index
      )
      // sorting method found from https://stackoverflow.com/questions/5619202/parsing-a-string-to-a-date-in-javascript?page=1&tab=scoredesc#tab-top
      .sort((a, b) => {
        //return convertStringToDate(b.date) - convertStringToDate(a.date);
        return b.orderNumber - a.orderNumber;
      });

    //console.log(filteredOrders);

    // Loop through unique uuid list and add uuid + purchase date to the formattedData object
    for (let i = 0; i < uniqueOrderID.length; i++) {
      let total = 0;

      formattedData[i] = {
        orderNumber: filteredOrders[i].orderNumber,
        purchaseDate: filteredOrders[i].date,
      };

      // calculate total cost of order (can include tickets multiple competitions within a single purchase)
      for (let x = 0; x < previousOrders.length; x++) {
        if (filteredOrders[i].orderNumber === previousOrders[x].orderNumber) {
          total += previousOrders[x].totalPrice;
        }
      }
      formattedData[i].totalPrice = total;
      //   formattedData[i].competitionIDs = competitionIDs;
    }

    setFormattedPreviousOrders(formattedData);

    setPreviousOrderCompetitionIDs(competitionIDs);
  }, [previousOrders]);

  const showOrderDetails = async (orderNumber) => {
    setPreviousPurchaseOrderNumber(orderNumber);
    setShowPreviousOrderList(false);
  };

  return (
    <div>
      {/* Initially show list of previous orders  */}
      {showPreviousOrderList ? (
        <div className="flex justify-center px-3">
          <table>
            <thead>
              <tr className="text-xs sm:text-base">
                <th>Order Number</th>
                <th>Purchased</th>
                {/* <th>Status</th> */}
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {formattedPreviousOrders &&
                formattedPreviousOrders.map((previousOrder, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-xs sm:text-base">
                        {previousOrder.orderNumber}
                      </td>
                      <td className="text-xs sm:text-base">
                        {previousOrder.purchaseDate}
                      </td>
                      {/* <td className="text-xs sm:text-base">Completed</td> */}
                      <td className="text-xs sm:text-base">
                        ${previousOrder.totalPrice}
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            showOrderDetails(
                              previousOrder.orderNumber,
                              previousOrder.competitionIDs
                            )
                          }
                          className="bg-gray-100 shadow-md px-2 py-1 hover:font-semibold hover:shadow-lg text-xs sm:text-base"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <PreviousOrder
          orderNumber={previousPurchaseOrderNumber}
          previousOrders={previousOrders}
          showPreviousOrderList={() => setShowPreviousOrderList(true)}
        />
      )}
    </div>
  );
};

export default PurchaseHistory;
