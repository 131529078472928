import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import { useShoppingCart } from "../context/useShoppingCart";
import CartCompetitionItem from "../components/CartCompetitionItem";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import {
  getDoc,
  doc,
  // addDoc,
  // setDoc,
  // runTransaction,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { formatToTimeZone } from "date-fns-timezone";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
//import SecurePay from "../components/SecurePay";
import Square from "../components/Square";
import CartPackage from "../components/CartPackage";

const Cart = () => {
  const { cartItems, removeFromCart, clearCart } = useShoppingCart();

  const navigate = useNavigate();

  const [grandTotal, setGrandTotal] = useState(0);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  //const [isLoading, setIsLoading] = useState(true);
  const [userIsSignedIn, setUserIsSignedIn] = useState(false);
  const [userUID, setUserUID] = useState("");
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  //const [billingDetailsComplete, setBillingDetailsComplete] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    street: "",
    townOrCity: "",
    postcode: "",
    country: "Australia",
  });

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    street,
    townOrCity,
    //state,
    postcode,
  } = formData;

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const streetRef = useRef(null);
  const townOrCityRef = useRef(null);
  const postcodeRef = useRef(null);

  // Require "billingDetailsComplete" - passing this to Square for GooglePay - otherwise user can technically check out without entering any billing information
  let billingDetailsComplete = false;

  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // If all billing fields are complete - set this to true
  if (
    firstName !== "" &&
    lastName !== "" &&
    isEmail(email) &&
    phoneNumber !== "" &&
    street !== "" &&
    townOrCity !== "" &&
    postcode !== ""
  ) {
    billingDetailsComplete = true;
  }

  let handlePurchaseRequiredInformation = [];

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const sendPurchaseSummaryEmail = async (emailOrderInformation) => {
    const body = JSON.stringify({
      emailOrderInformation,
      email,
      firstName,
      lastName,
      phoneNumber,
      street,
      townOrCity,
      postcode,
    });

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://sendpurchasesummaryemail-sendpurchasesummaryemail-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const sendInstantWinPrizeWinnerEmail = async (emailOrderInformation) => {
    const body = JSON.stringify({
      emailOrderInformation,
      email,
      firstName,
      lastName,
      phoneNumber,
      street,
      townOrCity,
      postcode,
    });

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://sendinstantwinprizewinneremail-sendinstantwinpriz-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const increaseOrderNumber = async () => {
    try {
      const response = await fetch(
        `https://increaseordernumber-increaseordernumber-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      const orderNumber = data.orderNumber;

      return orderNumber;
    } catch (error) {
      toast.error("Error increasing orderNumber", {
        autoClose: 2000,
      });
      throw new Error(error.message);
    }
  };

  const processUserRefund = async (squarePaymentId, amountToRefund) => {
    const body = JSON.stringify({
      squarePaymentId,
      amountToRefund,
    });

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://handlerefund-handlerefund-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();
      //console.log(data);

      console.log(data);

      return data;
    } catch (error) {
      //console.log(error.message);

      throw new Error(error.message);
    }
  };

  const notifyUserOfFailedAllocation = async (failedAllocations) => {
    for (let failedAllocation of failedAllocations) {
      toast.error(
        `Failed to allocate entry for competition "${failedAllocation.competitionTitle}", you have been refunded $${failedAllocation.packagePrice} for this competition.`,
        {
          autoClose: 10000,
        }
      );
    }
  };

  const getPurchaseDateAndTime = () => {
    const date = new Date();
    const format = "D-M-YYYY HH:mm:ss";
    return formatToTimeZone(date, format, {
      timeZone: "Australia/Sydney",
    });
  };

  const createOrActivateUserInPropello = async () => {
    const body = JSON.stringify({
      email,
      firstName,
      lastName,
    });

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://handlepropelloactivation-createorreactivateuserin-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      console.log(data);
    } catch (error) {
      throw new Error(error);
    }
  };

  // POST Request to Firebase Cloud Function
  const handleCheckout = async (data, validCartItems) => {
    let allocatedEntries = [];
    let successfulAllocations = [];
    let failedAllocations = [];
    let skippledAllocations = [];
    let purchaseOrderID = uuidv4();
    let squarePaymentId = data.payment.id;

    // For some reason - quantity needs to be a string as per Square docs
    for (let x = 0; x < validCartItems.length; x++) {
      handlePurchaseRequiredInformation[x] = {
        competitionTitle: validCartItems[x].competitionTitle,
        quantity: validCartItems[x].numberOfEntries.toString(),
        packagePrice: validCartItems[x].packagePrice,
      };
    }

    // Show beat loader for "Checkout" button
    setShowLoading(true);
    setCheckoutDisabled(true);

    // check if anything left in cartItems - if so, begin to process order
    // Square.js will run checkIfUserHasAlreadyEnteredCompetition() to perform preliminary check on competition cart items
    if (validCartItems.length > 0) {
      // increase orderNumber and pass to subsequent functions
      const orderNumber = await increaseOrderNumber();

      // Process valid cart items
      for (let cartItem of validCartItems) {
        const {
          id,
          competitionID,
          competitionTitle,
          numberOfEntries,
          packagePrice,
          packageLevel,
          //numberOfTickets,
          //totalPrice,
          //priceOfTicket,
          //competitionTitle,
          //competitionTotalTickets,
          //maxNumberOfEntries,
          //competitionImageURL,
        } = cartItem;

        // Define the request body
        const body = JSON.stringify({
          competitionID,
          competitionTitle,
          email,
          firstName,
          lastName,
          phoneNumber,
          street,
          townOrCity,
          postcode,
          numberOfEntries,
          packageLevel,
          packagePrice,
          competitionTitle,
          purchaseOrderID,
          squarePaymentId,
          userType: userIsSignedIn ? "signedInUser" : "guest",
          orderNumber,
          //numberOfTickets,
          //priceOfTicket,
          //competitionTotalTickets,
          //totalPrice,
          //maxNumberOfEntries,
          //competitionImageURL,
        });

        // Try allocate tickets
        try {
          // Send a POST request to your function
          const response = await fetch(
            `https://allocatetickets-allocatetickets-ybv5mc22oq-ts.a.run.app`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body,
            }
          );

          // Parse the response body
          const data = await response.json();

          // If the function returned an error, throw it
          if (data.error) {
            throw new Error(data.error);
          }
          console.log("Data: ", data);

          // Add the allocated tickets to the userAllocatedTickets object
          allocatedEntries[competitionID] = data.allocatedEntries;
          successfulAllocations.push(data);
          console.log("PurchaseOrderInformation", successfulAllocations);
        } catch (error) {
          // We have caught an error with the allocation of tickets for this comp - add to failedAllocations
          failedAllocations.push({
            competitionID,
            packagePrice,
            // competitionTitle,
            // totalPrice,
            error,
          });
          console.error(
            `Failed to allocate entry for competition ${competitionID}:`,
            error
          );
          toast.error(
            `Failed to allocate entry for ${competitionID}. ${error.message}`,
            {
              autoClose: 3000,
            }
          );
        }
      }

      // Once all allocations are processed - remove loader and enable checkout button
      setShowLoading(false);
      setCheckoutDisabled(false);

      let amountToRefund = 0;

      // if there were any failed allocations, process them
      if (failedAllocations.length > 0) {
        for (let failedAllocation of failedAllocations) {
          amountToRefund += failedAllocation.packagePrice;
        }

        console.log(
          `Processing refund amount of ${amountToRefund} via paymentId ${squarePaymentId}`
        );
        try {
          await processUserRefund(squarePaymentId, amountToRefund);
          notifyUserOfFailedAllocation(failedAllocations);
        } catch (error) {
          toast.error(
            "Failed to process refund, please contact us directly via email, Instagram or Facebook.",
            {
              autoClose: 3000,
            }
          );
          console.error(
            `Failed to process refund for paymentId ${squarePaymentId}:`,
            error
          );
        }
      }

      // If all cartItems failed to process - don't proceed to order confirmation page
      if (failedAllocations.length === validCartItems.length) {
        console.log("All ticket allocations failed");
        clearCart(); // Clear the cart if all allocations failed
        navigate("/");

        // return
      } else {
        // At least one of the competition ticket allocations was successful
        toast.success(
          "Thank you for your order! Check your email for your ticket numbers.",
          {
            autoClose: 5000,
          }
        );

        // Clear cart
        clearCart();

        // Get time of order - sending to order confirmation
        const date = new Date();
        const format = "D-M-YYYY HH:mm:ss";
        const purchaseDateAndTime = formatToTimeZone(date, format, {
          timeZone: "Australia/Sydney",
        });

        // SEND USER TO ORDER CONFIRMATION PAGE
        // Navigate to order-confirmation page with details
        navigate("/order-confirmation", {
          state: {
            successfulAllocations,
            email,
            firstName,
            lastName,
            phoneNumber,
            street,
            townOrCity,
            postcode,
            purchaseDateAndTime,
            orderNumber,
          },
        });

        // successful allocations copy required for email - facing bug without this
        let successfulAllocationsCopy = JSON.parse(
          JSON.stringify(successfulAllocations)
        );
        //console.log("successfulAllocaionsCopy:", successfulAllocationsCopy);
        // Check if there are any instant win prizes the user has won
        let hasInstantWinPrize = false;

        for (let successfulAllocation of successfulAllocations) {
          if (successfulAllocation.instantWinPrizes) {
            hasInstantWinPrize = true;
            break; // We found an instant win prize, so no need to continue checking
          }
        }

        if (hasInstantWinPrize) {
          await sendInstantWinPrizeWinnerEmail(successfulAllocationsCopy);
        } else {
          await sendPurchaseSummaryEmail(successfulAllocationsCopy);
        }
      }
    } else {
      toast.error("There are no items left in the cart.", { autoClose: 3000 });
    }
  };

  const handlePaymentError = async (error) => {
    toast.error(`Payment error: ${error}`, {
      autoClose: 4000,
    });
    console.log(error);
    throw new Error("Payment error: ", error);
  };

  // Set Grand Total
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    const getUserBillingInformation = async (userUID) => {
      if (userUID) {
        const userRef = doc(db, "users", userUID);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setFormData({
            firstName: userSnap.data().firstName,
            lastName: userSnap.data().lastName,
            phoneNumber: userSnap.data().phoneNumber,
            email: auth.currentUser.email,
            street: userSnap.data().street,
            townOrCity: userSnap.data().townOrCity,
            postcode: userSnap.data().postcode,
          });
        }
      }
    };

    //const userUID = user.uid;
    if (user) {
      setUserIsSignedIn(true);
      setUserUID(user.uid);

      // Search users in firebase and get user billing details
      getUserBillingInformation(userUID);
    }
    let grandTotal = 0;
    cartItems.forEach((item) => {
      grandTotal += item.packagePrice;
    });
    setGrandTotal(grandTotal);

    console.log(grandTotal);
  }, [cartItems, userUID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <Navbar /> */}
        <h3 className="mt-3 sm:mb-3 sm:mt-4 text-center text-lg font-semibold shadow-md pb-3 tracking-wider">
          MY CART | <span className="font-normal">CHECKOUT </span>
          <FontAwesomeIcon icon={faCartShopping} />
        </h3>

        {cartItems.length == 0 ? (
          <div className="flex justify-center">
            <h3 className="p-2 bg-gray-100 border-2 rounded-md border-gray-300/80 shadow-inner shadow-md w-11/12 text-sm sm:text-base mb-4">
              Cart is empty
            </h3>
          </div>
        ) : (
          // Container
          <>
            <div className="flex flex-col items-center px-2 sm:p-0">
              {/* Left hand column - Basket Details */}
              <div className="w-full lg:w-5/12 p-3 border-2 rounded-md border-gray-200/80 shadow-inner shadow-sm m-0 sm:mx-3 mb-4">
                <h3 className="text-base sm:text-lg font-semibold">Basket</h3>
                <p className="text-xs sm:text-base">
                  {cartItems.length} item(s) in basket
                </p>
                <div className="border-b-2 border-teal-400/50 mb-3"></div>
                {cartItems.map((item) => (
                  // <CartCompetitionItem
                  //   competitionID={item.competitionID}
                  //   numberOfTickets={item.numberOfTickets}
                  //   key={item.competitionID}
                  // />
                  <CartPackage
                    key={item.id}
                    id={item.id}
                    competitionID={item.competitionID}
                    numberOfEntries={item.numberOfEntries}
                    packageLevel={item.packageLevel}
                    packagePrice={item.packagePrice}
                  />
                ))}
              </div>

              {/* User Is Not Signed In */}
              <div className="flex flex-col">
                <div className="border-b-2 w-72 sm:w-96 border-teal-400/50 mb-3"></div>
                <h3 className="text-center text-lg font-semibold">
                  Billing Details
                </h3>
                {userIsSignedIn ? (
                  <></>
                ) : (
                  <div className="flex justify-center items-center gap-2 py-1 text-sm">
                    Have an account?
                    <button
                      className="font-semibold text-white px-3 py-1 border-2 rounded-lg border-black-400/80 shadow-inner shadow-xs bg-gradient-to-r from-green-300 to-teal-600 shadow-lg shadow-cyan-500/50"
                      onClick={() => navigate("/sign-in")}
                    >
                      Sign In
                    </button>
                  </div>
                )}

                <div className="flex flex-col w-72 sm:w-96 py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    First Name
                  </label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="text"
                    ref={firstNameRef}
                    id="firstName"
                    value={formData ? formData.firstName : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    Last Name
                  </label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="text"
                    ref={lastNameRef}
                    id="lastName"
                    value={formData ? formData.lastName : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">Email</label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="email"
                    ref={emailRef}
                    id="email"
                    value={formData ? formData.email : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    Phone Number
                  </label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="text"
                    ref={phoneNumberRef}
                    id="phoneNumber"
                    value={formData ? formData.phoneNumber : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    Street Address
                  </label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="text"
                    ref={streetRef}
                    id="street"
                    placeholder="House/apartment number and street name"
                    value={formData ? formData.street : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">
                    Town/City
                  </label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="text"
                    ref={townOrCityRef}
                    id="townOrCity"
                    value={formData ? formData.townOrCity : ""}
                    onChange={onChange}
                  ></input>
                </div>

                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">Postcode</label>
                  <input
                    className=""
                    type="text"
                    ref={postcodeRef}
                    id="postcode"
                    value={formData ? formData.postcode : ""}
                    onChange={onChange}
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-2">
                  <label className="text-sm font-semibold mb-1">Country</label>
                  <input
                    className="text-sm sm:text-base shadow-sm"
                    type="password"
                    id="country"
                    disabled={true}
                    placeholder="Australia"
                  ></input>
                </div>
              </div>

              {/* Right hand column - Billing Summary */}
              <div className="w-full lg:w-4/12 p-3 sm:border-t-2 rounded-md m-0 sm:mx-3 mb-1">
                <h3 className="text-base sm:text-lg font-semibold">
                  Billing Summary
                </h3>
                <div className="border-b-2 border-teal-400/50 mb-3"></div>
                <div className="flex justify-between">
                  <p className="text-sm sm:text-base font-semibold">
                    Grand Total
                  </p>
                  <p className="text-sm sm:text-base font-semibold">
                    ${grandTotal}
                  </p>
                </div>
              </div>

              {showLoading ? (
                <div className="flex flex-col py-5 justify-center align-center items-center">
                  <p className="text-xs sm:text-sm">Almost done..</p>
                  <BeatLoader color="#5EEAD4" size={20} />
                </div>
              ) : (
                <div className="mb-3">
                  <Square
                    grandTotal={grandTotal}
                    onPaymentSuccess={(data, validCartItems) =>
                      handleCheckout(data, validCartItems)
                    }
                    onPaymentError={handlePaymentError}
                    purchaseOrderInformation={handlePurchaseRequiredInformation}
                    email={email}
                    firstName={firstName}
                    lastName={lastName}
                    phoneNumber={phoneNumber}
                    street={street}
                    townOrCity={townOrCity}
                    postcode={postcode}
                    billingDetailsComplete={billingDetailsComplete}
                    formRefs={{
                      firstNameRef,
                      lastNameRef,
                      emailRef,
                      phoneNumberRef,
                      streetRef,
                      townOrCityRef,
                      postcodeRef,
                    }}
                  />
                  {/* <SquareWebPayment
                    grandTotal={grandTotal}
                    onPaymentSuccess={(data) => handleCheckout(email, data)}
                    onPaymentError={handlePaymentError}
                    purchaseOrderInformation={handlePurchaseRequiredInformation}
                    email={email}
                    firstName={firstName}
                    lastName={lastName}
                    phoneNumber={phoneNumber}
                    street={street}
                    townOrCity={townOrCity}
                    postcode={postcode}
                  />
                  <div className="flex items-center justify-center align-center mb-3 mt-3 sm:mt-0">
                    <span className="text-xs font-semibold">
                      Payment secured with{" "}
                    </span>
                    <img
                      src={securedWithSquare}
                      width="120"
                      alt="securedWithSquare"
                    />
                  </div> */}
                </div>
              )}
              {/* <Square2 grandTotal={grandTotal} /> */}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
