import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import JackImage from "../assets/ourTeamImageJackUpdated4.jpg";
import OlanImage from "../assets/ourTeamImageOlan.PNG";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <div className="mb-4 ">
          <div className="bg-gradient-to-r bg-white shadow-md">
            <p className="text-center text-lg font-semibold pt-3 pb-3 sm:pb-0 tracking-wider">
              ABOUT | <span className="font-normal">US</span>
            </p>
          </div>
          {/* About us paragraph */}
          <div className="flex justify-center">
            <div className="w-10/12 lg:w-7/12 2xl:w-6/12 text-sm sm:text-base">
              <p>
                Welcome to{" "}
                <span className="font-semibold">Lift Competitions</span>, where
                we believe that winning shouldn't require a PhD in luck or a
                lifetime of good karma. We're here to make winning easy,
                affordable, and above all life changing! We offer our players a
                chance to win a variety of luxury prizes at low cost.
              </p>

              <p>
                Our team is made up of a couple of competition-crazed
                individuals who have spent countless hours trying to win
                everything from giant teddy bears to jackpots on the pokey
                machines. We know how it feels to enter a competition and get
                nothing but a "better luck next time" email. That's why we
                created Lift Competitions - to level the playing field and give
                everyone a chance to win big prizes at low prices.
              </p>
              <div>
                <span className="font-semibold">The Lift Guarantee:</span>
                <ul>
                  <li>- Amazing prizes at low ticket prices and low odds.</li>
                  <li>
                    - Each draw will be livestreamed and available for viewing.
                  </li>
                  <li>
                    - Our prizes are carefully sourced and delivered in a timely
                    manner.
                  </li>
                  <li>
                    - Every ticket purchased for one of our competitions
                    benefits the Sydney Children's Hospitals Foundation.
                  </li>
                </ul>
              </div>
              <div>
                <span className="font-semibold">
                  Giving Back & Lifting Lives
                </span>
                <br></br>With each competition, we donate a percentage of our
                ticket sales to our partnerd charity - Sydney Children's
                Hospitals Foundation. We believe that spreading joy and making a
                positive impact on the world is just as important as winning a
                prize. For more information on Sydneys Children's Hospital
                Foundation, you can view our{" "}
                <Link className="text-black hover:font-semibold" to="/charity">
                  Charity
                </Link>{" "}
                page.
                <br></br>
                <br></br>So come on in, join the fun, and who knows - you might
                just be our next lucky winner! Or at the very least, you'll be
                giving to a good cause and lifting someone's life!
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Meet the team */}
      {/* <div className="bg-polygonFilled">
        <p className="text-center text-lg font-semibold mt-2 mb-4 py-3">
          Our Team
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center mb-2 text-sm sm:text-base">
          <div className="w-7/12 bg-white md:w-96 mx-5 p-3 shadow-md rounded-lg mb-5 sm:mb-0">
            <div className="flex justify-center mb-3">
              <img
                className="rounded-full w-5/12 md:w-5/12 2xl:w-4/12"
                src={JackImage}
                alt="Team member"
              />
            </div>

            <p className="font-semibold text-center">Jack Reynolds</p>
            <p>
              Our director Jack is the team geek who loves creating web/mobile
              applications, automating boring stuff and the process of taking a
              project from start to finish. Jack studied Computer Science and
              Software Engineering in university back in Ireland and today,
              works as a Technical Consultant in Sydney.
            </p>
          </div>
          <div className="w-7/12 bg-white md:w-96 mx-5 p-3 shadow-md rounded-lg mb-5 sm:mb-0">
            <div className="flex justify-center mb-3">
              <img
                className="rounded-full w-5/12 md:w-5/12 2xl:w-4/12"
                src={OlanImage}
                alt="Team member"
              />
            </div>
            <p className="font-semibold text-center">Olan Ahern</p>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos
              ullam rerum molestias eaque officia est sed sequi voluptas
              molestiae veritatis, dolores placeat eum id saepe? Aspernatur nam
              ut fugiat provident. Sint incidunt tempore nemo libero fuga id
              dolorem? Culpa et necessitatibus, deleniti iusto at saepe error
              ducimus illum amet. Id sapiente vel animi alias dolores enim
              voluptatum ullam, unde natus.
            </p>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default AboutUs;
