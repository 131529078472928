import React, { useState, useEffect } from "react";

const ClubsFAQs = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqs = [
    {
      question: "Do we need any regulatory approval to operate a fundraiser?",
      answer:
        "Lift Competitions holds a registered charitable fundraising license to operate within the state of NSW (CFN/26601). Your club may already possess an appropriate license if it is involved in certain fundraising activities. Our Club Lotto will guide you through every step of applying for a license if required. Lift Competitions will need an 'Authority to Fundraise' on behalf of the club and will provide a template for this during the onboarding of each club, making the onboarding process quicker and simpler.",
    },
    {
      question:
        "Do we require an online payment portal to manage card payments?",
      answer:
        "No, Lift Competitions utilizes Square for a merchant account and payment processing. This ensures card details are processed by Square only and are never stored or processed on our site.",
    },
    {
      question:
        "Are there any setup costs involved with onboarding and creation of the fundraiser?",
      answer: "There are no setup costs with Lift Competitions.",
    },
    {
      question: "What happens if the competition doesn't sell out?",
      answer:
        "Lift Competitions reserves the right to extend the draw date up to three times if the competition does not sell out. We also reserve the right to issue refunds to all competition participants if ticket sales do not exceed 80% of the available tickets.",
    },
    {
      question: "Who can enter the competitions?",
      answer:
        "You must be over 16 years old and reside in Australia to enter any of the competitions. Lift Competitions Pty Ltd reserves the right to disallow prize allocation if you win and do not meet this criteria.",
    },
    {
      question: "How is the winner of each club fundraiser chosen?",
      answer:
        "For club fundraisers, it is the responsibility of the club to run the competition lottery once the draw has sold out or the draw date has been reached. Lift Competitions will provide the club with a document detailing all entrants, including first name, last name, email, phone number, and ticket number. This entrant list will also be available on the 'Entrants List' page on the Lift Competitions site, accessible from the site footer.",
    },
    {
      question: "Is our fundraiser part of a larger fundraiser?",
      answer:
        "No, your fundraiser is unique and involves only your community, allowing you to tailor it to your specific needs. You can control the jackpot, rollover increments, other prize levels, as well as pricing and promotion.",
    },
    {
      question: "How can I check the entrant list?",
      answer:
        "An entrant list will be provided to the club representative once the draw date has been reached or the fundraiser has sold out. This list will also be published on the 'Entrants List' page of the Lift Competitions site, with the page link available in the website footer.",
    },
    {
      question: "When do we receive our money?",
      answer:
        "Payments are processed via Square and transferred to the club representative's account (minus the Square transfer fee) within 10 days of the competition's conclusion.",
    },
    {
      question: "Are there any other associated costs with the competition?",
      answer:
        "Under normal circumstances, there should be no additional costs arising from our service. However, in the rare event that fraud or card chargebacks for disputed or failed payments result in extra charges being levied on Lift Competitions by our payment providers, those costs will be communicated to you and charged at cost.",
    },
    {
      question: "Can the lottery be entered from overseas?",
      answer:
        "No, the lottery must be entered by individuals residing in Australia.",
    },
    {
      question: "Are there limits to what a player can spend?",
      answer:
        "The maximum number of entries can be configured per competition. This limit ensures a player cannot submit more entries than this amount using the same email address or account.",
    },
    {
      question: "Are refunds allowed?",
      answer:
        "Lift Competitions does not offer refunds under any circumstances, as per the terms and conditions.",
    },
  ];

  const toggleFAQ = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div>
      <h2 className="text-center pt-2 font-semibold">FAQs</h2>
      <div className="flex w-full justify-center">
        <hr className="w-1/2" />
      </div>
      <div className="flex justify-center text-sm md:text-base">
        <div className="w-11/12 md:w-6/12 lg:w-8/12 xl:w-6/12">
          <div className="faq">
            {faqs.map((faq, i) => (
              <div key={i} onClick={() => toggleFAQ(i)} className="faq-item">
                <div className="faq-question">
                  {faq.question}
                  <span>{selectedQuestion === i ? "-" : "+"}</span>
                </div>
                <div
                  className={`faq-answer ${
                    selectedQuestion === i ? "open" : ""
                  }`}
                >
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubsFAQs;
