import React, { useState } from "react";
import { db } from "../firebase.config";
import { doc, updateDoc } from "firebase/firestore";
import { format } from "date-fns";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

const AdminViewIndividualCompetition = ({ competition }) => {
  const createFormData = (competition) => {
    const baseData = {
      competitionTitle: competition.data.competitionTitle,
      numberOfTickets: competition.data.numberOfTickets,
      competitionDescription: competition.data.competitionDescription,
      isActive: competition.data.isActive,
      maxNumberOfEntries: competition.data.maxNumberOfEntries,
      isFeatured: competition.data.isFeatured,
      isClubFundraiser: competition.data.isClubFundraiser,
      priceOfTicket: competition.data.priceOfTicket,
      drawDate: competition.data.drawDate,
      competitionId: competition.id,
      bannerURL: competition.data.bannerURL,
      imageURLs: competition.data.imageURLs,
      timestamp: competition.data.timestamp,
      isClosed: competition.data.isClosed,
    };

    // Only add instantWinPrizeDetails if it exists and is not an empty array
    if (
      competition.data.instantWinPrizeDetails &&
      competition.data.instantWinPrizeDetails.length > 0
    ) {
      baseData.instantWinPrizeDetails = competition.data.instantWinPrizeDetails;
    }

    return baseData;
  };

  const [formData, setFormData] = useState(createFormData(competition));

  const [showSpinner, setShowSpinner] = useState(false);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    // Reference to the competition document
    const docRef = doc(db, "competitions", competition.id);

    console.log(formData);

    try {
      await updateDoc(docRef, {
        ...formData,
        // Convert numerical string to number, if needed
        numberOfTickets: parseInt(formData.numberOfTickets),
        priceOfTicket: parseFloat(formData.priceOfTicket),
      });
      toast.success("Updated competition details successfully", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error updating competition: ", error);
      alert("Failed to update competition.");
    }
  };

  async function deleteCompetition(competitionId) {
    const competitionRef = db.collection("competitions").doc(competitionId);

    // Delete userPurchases subcollection
    await deleteSubcollection(competitionRef, "userPurchases");

    // Delete ticketsCollection subcollection
    await deleteSubcollection(competitionRef, "ticketsCollection");

    // Once the subcollections are deleted, delete the competition document
    await competitionRef.delete();

    console.log(
      `Competition ${competitionId} and all its subcollections have been deleted.`
    );
  }

  async function deleteSubcollection(parentRef, subcollectionName) {
    const snapshot = await parentRef.collection(subcollectionName).get();

    // Firestore batch writes can write/delete up to 500 documents in one go.
    // If you expect more documents, consider paginating them.
    const batch = db.batch();

    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    console.log(
      `Subcollection ${subcollectionName} of ${parentRef.id} deleted.`
    );
  }

  const handleDeleteConfirmation = () => {
    setShowConfirmDeletion(true);
  };

  const confirmDelete = () => {
    deleteCompetition(competition.id);
    setShowConfirmDeletion(false); // Close the confirmation popup
    toast.success("Competition has been deleted", { autoClose: 3000 });
  };

  const cancelDelete = () => {
    setShowConfirmDeletion(false); // Close the confirmation popup without deleting
  };

  const handleCloseCompetition = async (competitionId) => {
    const competitionRef = db.collection("competitions").doc(competitionId);

    // Set the 'capital' field of the city
    const res = await competitionRef.update({ isClosed: true });
    toast.success("Competition has been marked as 'Closed'", {
      autoClose: 3000,
    });
  };

  const createEntrantList = async (competitionId) => {
    const body = JSON.stringify({ competitionId });
    console.log(body);

    try {
      setShowSpinner(true);
      // Send a POST request to your function
      const response = await fetch(
        ` https://manuallycreateentrantlist-manuallycreateentrantli-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();
      console.log("Data: ", data);
      setShowSpinner(false);
      toast.success("Entrant list created", { autoClose: 3000 });
    } catch (error) {
      console.log(error.message);
      setShowSpinner(false);
    }
  };

  const formatDate = (firebaseTimestamp) => {
    if (!firebaseTimestamp) {
      // Return a placeholder or empty string if the timestamp is not available
      return "N/A"; // or return "";
    }

    // Convert Firestore timestamp to JavaScript Date object
    const date = firebaseTimestamp.toDate();
    // Format the date to dd-MM-yyyy
    return format(date, "dd/MM/yyyy");
  };

  return (
    <div className="flex flex-col items-center p-4 ">
      <p className="font-semibold text-xl">Competition Details</p>
      <form onSubmit={handleUpdateDetails} className="w-7/12">
        <div className="my-3 flex">
          <label className="w-3/12 font-semibold" htmlFor="competitionTitle">
            ID
          </label>
          <input
            type="text"
            className="w-9/12"
            id="competitionId"
            name="competitionId"
            value={formData.competitionId}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="competitionTitle">
            Title
          </label>
          <input
            type="text"
            className="w-9/12"
            id="competitionTitle"
            name="competitionTitle"
            value={formData.competitionTitle}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="createdDate">
            Created Date
          </label>
          <input
            type="text"
            className="w-9/12"
            id="createdDate"
            name="createdDate"
            value={formatDate(formData.timestamp)}
            onChange={handleChange}
            readOnly
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="numberOfTickets">
            Number of Tickets
          </label>
          <input
            type="number"
            className="w-9/12"
            id="numberOfTickets"
            name="numberOfTickets"
            value={formData.numberOfTickets}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="drawDate">
            Draw Date
          </label>
          <input
            type="date"
            className="w-9/12"
            id="drawDate"
            name="drawDate"
            value={formData.drawDate}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="priceOfTicket">
            Price of Ticket (AUD)
          </label>
          <input
            type="number"
            className="w-9/12"
            id="priceOfTicket"
            name="priceOfTicket"
            value={formData.priceOfTicket}
            onChange={handleChange}
          />
        </div>
        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="maxNumberOfEntries">
            Max Number of Entries
          </label>
          <input
            type="number"
            className="w-9/12"
            id="maxNumberOfEntries"
            name="maxNumberOfEntries"
            value={formData.maxNumberOfEntries}
            onChange={handleChange}
          />
        </div>

        <div className="mt-3 mb-4 flex">
          <label
            className="w-3/12 font-semibold"
            htmlFor="competitionDescription"
          >
            Description
          </label>
          <textarea
            className="w-9/12 border p-2 border-gray-200"
            rows={10}
            id="competitionDescription"
            name="competitionDescription"
            value={formData.competitionDescription}
            onChange={handleChange}
          />
        </div>

        <div className="my-3">
          <label className="w-3/12 font-semibold" htmlFor="bannerURL">
            Banner URL
          </label>
          <input
            type="text"
            className="w-9/12"
            id="bannerURL"
            name="bannerURL"
            value={formData.bannerURL}
            onChange={handleChange}
          />
        </div>

        <div className="flex items-center align-center my-3">
          <label htmlFor="isActiveCheckbox" className="font-semibold">
            <input
              type="checkbox"
              name="isActive"
              className="w-5 h-5 dark:border-gray-600 mr-3"
              id="isActiveCheckbox"
              checked={formData.isActive}
              onChange={handleChange}
            />
            Is Active
          </label>
        </div>
        <div className="flex items-center align-center">
          <label htmlFor="isFeaturedCheckbox" className="font-semibold">
            <input
              type="checkbox"
              name="isFeatured"
              className="w-5 h-5 dark:border-gray-600 mr-3"
              id="isFeaturedCheckbox"
              checked={formData.isFeatured}
              onChange={handleChange}
            />
            Is Featured (Homepage Banner)
          </label>
        </div>

        <div className="flex items-center align-center mt-3">
          <label htmlFor="isClosed" className="font-semibold">
            <input
              type="checkbox"
              name="isClosed"
              className="w-5 h-5 dark:border-gray-600 mr-3"
              id="isClosed"
              checked={formData.isClosed}
              onChange={handleChange}
            />
            Is Closed
          </label>
        </div>

        <div className="flex items-center align-center mt-3">
          <label htmlFor="isClubFundraiser" className="font-semibold">
            <input
              type="checkbox"
              name="isClubFundraiser"
              className="w-5 h-5 dark:border-gray-600 mr-3"
              id="isClubFundraiser"
              checked={formData.isClubFundraiser}
              onChange={handleChange}
            />
            Is Club Fundraiser
          </label>
        </div>

        {/* Confirmation Popup */}
        {showConfirmDeletion && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <div className="bg-white p-5 rounded shadow-lg">
              <p>Are you sure you want to delete this competition?</p>
              <div className="flex justify-around mt-4">
                <button
                  onClick={confirmDelete}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Yes
                </button>
                <button
                  onClick={cancelDelete}
                  className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center mt-3 gap-5">
          {/* Add inputs for other details you wish to edit */}
          <button
            type="submit"
            className="bg-teal-500 text-white font-semibold px-4 py-2 shadow-md rounded hover:bg-teal-700 hover:shadow-lg"
          >
            Update Details
          </button>
        </div>
      </form>
      <button
        type="submit"
        className="mt-3 bg-teal-700 text-white font-semibold px-4 py-2 shadow-md rounded hover:bg-teal-800 hover:shadow-lg"
        onClick={() => createEntrantList(competition.id)}
      >
        {showSpinner ? <BeatLoader color="white" /> : "Create Entrant List"}
      </button>

      <div className="flex justify-center mt-3 gap-5">
        {/* Add inputs for other details you wish to edit */}
        <button
          onClick={handleDeleteConfirmation}
          className="bg-red-500 text-white font-semibold px-4 py-2 shadow-md rounded hover:bg-red-700 hover:shadow-lg"
        >
          Delete Competition
        </button>
      </div>
    </div>
  );
};

export default AdminViewIndividualCompetition;
