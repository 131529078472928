import React, { useState } from "react";
import Spinner from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faTicket,
  faTrophy,
  faCalendarCheck,
  faMoneyBillAlt,
  faUserAlt,
  faXmarkCircle,
  faBoltLightning,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc } from "firebase/firestore";
import { db, app } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { useShoppingCart } from "../context/useShoppingCart";
import { format, differenceInDays, parseISO } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import SCHF from "../assets/SCHF.svg";
import square from "../assets/squareLogo.png";
import countdownClock from "../assets/countdownclock.png";
import InstantWinPrizeCard from "./InstantWinPrizeCard";
import Countdown from "react-countdown";
import Packages from "./Packages";

const CompetitionLeftColumn = ({ competition, isLoading, competitionID }) => {
  //const [purchaseTicketsNumber, setPurchaseTicketsNumber] = useState(1);

  const windowWidth = window.innerWidth;

  const { addToCart, getNumberOfEntriesInCart } = useShoppingCart();

  const [livingInAustralia, setlivingInAustralia] = useState(false);
  const [showPrizeDetails, setShowPrizeDetails] = useState(true);
  const [showInstantWinPrizes, setShowInstantWinPrizes] = useState(true);
  const [addingToCart, setAddingToCart] = useState(false);

  const navigate = useNavigate();

  function formatDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  }

  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Event has occurred!</span>;
    } else {
      // Function to format label based on the value
      const formatLabel = (value, singular, plural) =>
        `${value} ${value === 1 ? singular : plural}`;

      // Render a countdown
      return (
        <span>
          {formatLabel(days, "day", "days")},{" "}
          {formatLabel(hours, " hour", " hours")},{" "}
          {formatLabel(minutes, " minute", " minutes")},{" "}
          {formatLabel(seconds, " second", " seconds")}
        </span>
      );
    }
  };

  const formattedDate = formatDate(competition.drawDate);

  function numberOfDaysUntilDraw(competitionDateString) {
    const competitionDate = parseISO(competitionDateString);
    const currentDate = new Date();

    const daysDifference = differenceInDays(competitionDate, currentDate);

    return daysDifference;
  }

  if (numberOfDaysUntilDraw(competition.drawDate) < 10) {
    const numberOfDays = numberOfDaysUntilDraw(competition.drawDate);
  }

  const handleAddToCart = async (packageLevel) => {
    const auth = getAuth(app);
    setAddingToCart(true);
    const user = auth.currentUser;
    let numberOfEntries = 0;
    let packagePrice = 0;

    // Set the numberOfEntries based on packageLevel
    if (packageLevel === "bronze") {
      numberOfEntries = 1;
      packagePrice = numberOfEntries * competition.priceOfEntry;
    } else if (packageLevel === "silver") {
      numberOfEntries = 3;
      packagePrice = competition.priceOfEntry * 2.5;
    } else if (packageLevel === "gold") {
      numberOfEntries = 5;
      packagePrice = competition.priceOfEntry * 4;
    }

    const totalTicketsForThisCompetitionInCart =
      getNumberOfEntriesInCart(competitionID);

    if (livingInAustralia === false) {
      toast.error(
        'You must select "I am living in Australia" to add to cart.',
        {
          autoClose: 2000,
        }
      );
      setAddingToCart(false);
      return;
    }

    let competitionImageURL = "";

    const getCompImageURL = async () => {
      const docRef = doc(db, "competitions", competitionID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        competitionImageURL = docSnap.data().imageURLs[0];
      }
    };

    await getCompImageURL();

    if (
      numberOfEntries + totalTicketsForThisCompetitionInCart >
      competition.maxNumberOfEntries
    ) {
      toast.error(
        `Max number of entries per person is ${competition.maxNumberOfEntries}.`,
        { autoClose: 1000 }
      );
      setAddingToCart(false);
    } else if (numberOfEntries > competition.numberOfEntriesRemaining) {
      toast.error(
        `There are only ${competition.numberOfEntriesRemaining} ticket(s) remaining for this competition.`,
        { autoClose: 1000 }
      );
      setAddingToCart(false);
    } else {
      addToCart(
        competitionID,
        competition.competitionTitle,
        numberOfEntries,
        packagePrice,
        packageLevel
        // competition.priceOfTicket,
        // competition.competitionTitle,
        // competition.numberOfTickets,
        // competition.maxNumberOfEntries,
        // competitionImageURL,
      );

      setAddingToCart(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <>
        {/* Container */}
        <div className="md:w-full md:px-20 lg:px-0 lg:w-5/12 mr-3">
          <h2 className="text-lg sm:text-xl font-bold text-gray-800 my-3">
            {competition.competitionTitle}
          </h2>
          <div className="border-b-2 border-teal-400/50 mb-3"></div>

          {/* INSTANT WIN COMP TITLE */}
          {competition.instantWinPrizeDetails ? (
            <div>
              <p className="font-semibold text-center text-teal-400">
                <FontAwesomeIcon icon={faBoltLightning} /> INSTANT WIN
                COMPETITION <FontAwesomeIcon icon={faBoltLightning} />
              </p>
            </div>
          ) : null}

          {/* COMPETITION DETAILS */}
          <div className="bg-white p-3 rounded-md shadow-md space-y-3">
            {/* Price */}
            {/* <div className="flex gap-3 items-center p-3 bg-gray-100 rounded-md">
              <FontAwesomeIcon
                icon={faMoneyBillAlt}
                className="text-teal-500"
              />
              <span className="text-gray-800 font-semibold">
                ${competition.priceOfTicket} per ticket
              </span>
            </div> */}

            {/* Number of tickets */}
            <div className="flex gap-3 items-center p-3 bg-gray-100 rounded-md">
              <FontAwesomeIcon icon={faTicket} className="text-teal-500" />
              <span className="text-gray-800 font-semibold">
                {competition.numberOfEntries} entries total
              </span>
            </div>

            {/* Instant Win Prizes */}
            {competition.instantWinPrizeDetails && (
              <div className="flex gap-3 items-center p-3 bg-gray-100 rounded-md">
                <FontAwesomeIcon
                  icon={faBoltLightning}
                  className="text-teal-500"
                />
                <span className="text-gray-800 font-semibold">
                  {competition.instantWinPrizeDetails.length} instant win prizes
                </span>
              </div>
            )}

            {/* Maximum entries */}
            <div className="flex gap-3 items-center p-3 bg-gray-100 rounded-md">
              <FontAwesomeIcon icon={faUserAlt} className="text-teal-500" />
              <span className="text-gray-800 font-semibold">
                {competition.maxNumberOfEntries} entries maximum per person
              </span>
            </div>

            {/* Draw Date */}
            <div className="flex gap-3 items-center p-3 bg-gray-100 rounded-md">
              <FontAwesomeIcon
                icon={faCalendarCheck}
                className="text-teal-500"
              />
              <span className="text-gray-800 font-semibold">
                Draw date: {formattedDate}
              </span>
            </div>
          </div>

          {/* Number of tickets remaining */}
          <div className="pt-4">
            <div className="text-sm font-bold sm:text-base pb-1">
              Entries allocated:{" "}
              {competition.numberOfEntries -
                competition.numberOfEntriesRemaining}
              /{competition.numberOfEntries}
            </div>
            <div>
              <ProgressBar
                completed={`${
                  Number(competition.numberOfEntries) -
                  Number(competition.numberOfEntriesRemaining)
                }`}
                maxCompleted={Number(competition.numberOfTickets)}
                //bgColor="#1f2937"
                bgColor={
                  Number(competition.numberOfEntries) /
                    (Number(competition.numberOfEntries) -
                      Number(competition.numberOfEntriesRemaining)) <
                  1.1
                    ? "#BE123C"
                    : "#14B8A6"
                }
              />
            </div>
          </div>

          <div className="flex mt-4 text-xs sm:text-base text-white justify-center items-center font-semibold bg-teal-500 text-center rounded-sm shadow-md py-2 gap-1">
            {/* <FontAwesomeIcon icon={faClock} /> */}
            <img
              className="w-7 sm:w-10"
              src={countdownClock}
              alt="countdown-clock"
            />

            <Countdown
              date={competition.drawDate}
              renderer={CountdownRenderer}
            />
          </div>

          <div className="mt-4 mb-2 bg-white shadow-sm w-9/12 sm:w-8/12 xl:w-7/12 p-2 hover:font-semibold">
            <input
              type="checkbox"
              id="livingInAustralia"
              className="hover:cursor-pointer"
              onChange={() => setlivingInAustralia(!livingInAustralia)}
            ></input>
            {"  "}
            <label
              htmlFor="livingInAustralia"
              className="text-sm sm:text-base hover:cursor-pointer"
            >
              I am living in Australia
            </label>
          </div>

          {/* Container */}
          {/* <div className="flex justify-left"> */}
          {/* Select number of tickets */}
          {/* {purchaseTicketsNumber == 1 ? (
              <div className="flex w-fit flex-row items-center justify-center rounded-sm border border-black-500">
                <button
                  className="mx-2 text-gray-300"
                  disabled={true}
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber - 1)
                  }
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <p className="px-4 mt-3 font-semibold text-lg ">
                  {purchaseTicketsNumber}
                </p>
                <button
                  className="mx-2"
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber + 1)
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            ) : purchaseTicketsNumber == competition.maxNumberOfEntries ? (
              <div className="flex w-fit flex-row items-center justify-center rounded-sm border border-black-500 ">
                <button
                  className="mx-2"
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber - 1)
                  }
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <p className="px-4 mt-3 font-semibold text-lg ">
                  {purchaseTicketsNumber}
                </p>
                <button
                  className="mx-2 text-gray-300"
                  disabled={true}
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber + 1)
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            ) : (
              <div className="flex w-fit flex-row items-center justify-center rounded-sm border border-black-500">
                <button
                  className="mx-2"
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber - 1)
                  }
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <p className="px-4 mt-3 font-semibold text-lg ">
                  {purchaseTicketsNumber}
                </p>
                <button
                  className="mx-2"
                  onClick={() =>
                    setPurchaseTicketsNumber(purchaseTicketsNumber + 1)
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            )} */}

          {/* Add to cart button, tickets sold out, or competition closed */}
          {/* {numberOfTicketsRemaining > 0 && competition.isActive ? (
              competition.isClosed ? (
                <button
                  className="ml-5 px-3 border-2 rounded-lg border-gray-400 text-gray-500 font-semibold text-sm sm:text-base"
                  disabled={true}
                >
                  COMPETITION CLOSED
                </button>
              ) : (
                <button
                  className={
                    livingInAustralia
                      ? "font-semibold text-white ml-5 px-3 border-2 rounded-lg border-black-400/80 shadow-inner shadow-xs bg-gradient-to-r from-green-300 to-teal-600 shadow-lg shadow-cyan-500/50 transition duration-500 hover:border-teal-300 hover:font-semibold"
                      : "ml-5 px-3 border-2 rounded-lg border-gray-100/80 shadow-inner shadow-xs transition duration-500"
                  }
                  onClick={() => handleAddToCart()}
                >
                  Add to cart | $
                  {purchaseTicketsNumber * competition.priceOfTicket}
                </button>
              )
            ) : (
              <button
                className="ml-5 px-3 border-2 rounded-lg border-rose-500 text-rose-500 font-semibold text-sm sm:text-base"
                disabled={true}
              >
                <FontAwesomeIcon icon={faXmarkCircle} /> SOLD OUT
              </button>
            )}
          </div> */}

          <Packages
            competition={competition}
            livingInAustralia={livingInAustralia}
            handleAddToCart={handleAddToCart}
            addingToCart={addingToCart}
          />

          {/* Competition Details */}
          <div className="bg-gray-100 pt-2 pb-1 px-2 mt-3 mb-3 rounded shadow-inner shadow-md">
            <div className="text-sm sm:text-base flex justify-between mb-2">
              <span className="font-bold">
                <FontAwesomeIcon icon={faTrophy} /> Prize Details{" "}
              </span>

              <button onClick={() => setShowPrizeDetails(!showPrizeDetails)}>
                <FontAwesomeIcon icon={showPrizeDetails ? faMinus : faPlus} />
              </button>
            </div>
            <p className="text-sm sm:text-base new-line">
              {showPrizeDetails ? competition.competitionDescription : null}
            </p>
          </div>

          {competition.instantWinPrizeDetails ? (
            <div className="bg-gray-100 pt-2 pb-1 px-2 mt-2 mb-3 rounded shadow-inner shadow-md">
              <div className="text-sm sm:text-base flex justify-between mb-2">
                <span className="font-bold">
                  <FontAwesomeIcon icon={faBoltLightning} /> Instant Win Prizes{" "}
                </span>

                <button
                  onClick={() => setShowInstantWinPrizes(!showInstantWinPrizes)}
                >
                  <FontAwesomeIcon
                    icon={showInstantWinPrizes ? faMinus : faPlus}
                  />
                </button>
              </div>
              <div className="grid w-full grid-cols-1 gap-2">
                {/* <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2"> */}
                {showInstantWinPrizes
                  ? competition.instantWinPrizeDetails.map(
                      (instantWinPrize, index) => (
                        <InstantWinPrizeCard
                          title={instantWinPrize.title}
                          winningTicketNumber={
                            instantWinPrize.winningTicketNumber
                          }
                          imageUrl={instantWinPrize.imageUrl}
                          status={instantWinPrize.status}
                          winner={instantWinPrize.winner}
                          key={index}
                        />
                      )
                    )
                  : null}
              </div>
            </div>
          ) : (
            <></>
          )}

          {windowWidth < 1000 ? (
            <div>
              <div className="text-xs sm:text-sm mt-4">
                By entering this competition, you have confirmed that you have
                read and agree to our{" "}
                <Link to="/terms-and-conditions">Terms and Conditions</Link>.
                Please note that the manufacturers involved in this competition
                do not endorse this competition and are in no way associated
                with Lift Competitions.
              </div>
              <div className="flex items-center justify-center mt-3">
                <p className="mt-3 font-semibold text-xs sm:text-sm ">
                  Payments secured with
                </p>
                <img
                  src={square}
                  height="300"
                  width="150"
                  alt="squarePayment"
                />
              </div>
              <div className="pt-2 pb-3 pl-2 flex items-center justify-center gap-4">
                <p className="text-xs font-semibold">
                  Every purchased ticket supports
                </p>
                {/* <p className="text-xs sm:text-sm">
                          <FontAwesomeIcon icon={faHandHoldingHeart} /> Every
                          ticket purchased supports
                        </p> */}
                <img
                  src={SCHF}
                  width={windowWidth < 1000 ? "100" : "150"}
                  alt="SCHFCharityImage"
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </>
    );
  }
};

export default CompetitionLeftColumn;
