import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <div className="flex flex-col w-full items-center">
          <p className="w-full text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
            PRIVACY | <span className="font-normal">POLICY</span>
          </p>
          <div className="w-9/12 md:w-6/12">
            <p>
              Lift Competitions is committed to providing quality services to
              you and this policy outlines our ongoing obligations to you in
              respect of how we manage your Personal Information. We have
              adopted the Australian Privacy Principles (APPs) contained in the
              Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way
              in which we collect, use, disclose, store, secure and dispose of
              your Personal Information. A copy of the Australian Privacy
              Principles may be obtained from the website of The Office of the
              Australian Information Commissioner at https://www.oaic.gov.au/.
            </p>
            <h3 className="text-lg font-semibold">
              What is Personal Information and why do we collect it?
            </h3>
            <p className="text-sm sm:text-base">
              Personal Information is information or an opinion that identifies
              an individual. Examples of Personal Information we collect
              includes names, addresses, email addresses, phone and facsimile
              numbers. This Personal Information is obtained via purchasing
              tickets, user sign-up and subscribing to our mailing list. We
              collect your Personal Information for the primary purpose of
              providing our services to you, providing information to our
              clients and marketing. We may also use your Personal Information
              for secondary purposes closely related to the primary purpose, in
              circumstances where you would reasonably expect such use or
              disclosure. You may unsubscribe from our mailing/marketing lists
              at any time by contacting us in writing. When we collect Personal
              Information we will, where appropriate and where possible, explain
              to you why we are collecting the information and how we plan to
              use it.
            </p>
            <h3 className="text-lg font-semibold">Sensitive Information</h3>
            <p className="text-sm sm:text-base">
              Sensitive information is defined in the Privacy Act to include
              information or opinion about such things as an individual's racial
              or ethnic origin, political opinions, membership of a political
              association, religious or philosophical beliefs, membership of a
              trade union or other professional body, criminal record or health
              information. Lift Competitions does not collect any Sensitive
              Information as defined by the statement above.
            </p>
            <h3 className="text-lg font-semibold">
              Disclosure of Personal Information
            </h3>{" "}
            <p className="text-sm sm:text-base">
              Your Personal Information may be disclosed in a number of
              circumstances including the following: third parties where you
              consent to the use or disclosure; and where required or authorised
              by law.
            </p>
            <h3 className="text-lg font-semibold">
              Security of Personal Information
            </h3>{" "}
            <p className="text-sm sm:text-base">
              Your Personal Information is stored in a manner that reasonably
              protects it from misuse and loss and from unauthorized access,
              modification or disclosure. When your Personal Information is no
              longer needed for the purpose for which it was obtained, we will
              take reasonable steps to destroy or permanently de-identify your
              Personal Information. However, most of the Personal Information is
              or will be stored in client files which will be kept by us for a
              minimum of 7 years.
            </p>
            <h3 className="text-lg font-semibold">
              Access to your Personal Information
            </h3>{" "}
            <p className="text-sm sm:text-base">
              You may access the Personal Information we hold about you and to
              update and/or correct it, subject to certain exceptions. If you
              wish to access your Personal Information, please contact us in
              writing. If you are a member with Lift Competitions, you can also
              view your account information within the My Account page. Lift
              Competitions will not charge any fee for your access request. In
              order to protect your Personal Information we may require
              identification from you before releasing the requested
              information.
            </p>
            <h3 className="text-lg font-semibold">
              Maintaining the Quality of your Personal Information
            </h3>{" "}
            <p className="text-sm sm:text-base">
              It is an important to us that your Personal Information is up to
              date. We will take reasonable steps to make sure that your
              Personal Information is accurate, complete and up-to-date. If you
              find that the information we have is not up to date or is
              inaccurate, please advise us as soon as practicable so we can
              update our records and ensure we can continue to provide quality
              services to you. Ensuring our users personal information is
              correct, ensures we have the appropriate contact details for prize
              winners.
            </p>
            <h3 className="text-lg font-semibold">Policy Updates</h3>{" "}
            <p className="text-sm sm:text-base">
              This Policy may change from time to time and is available on our
              website. Privacy Policy Complaints and Enquiries If you have any
              queries or complaints about our Privacy Policy please contact us
              at: info@liftcompetitions.com.au.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
