import React, { useState } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import SCHF from "../assets/SCHF.svg";

const OrderConfirmation = ({ route }) => {
  const location = useLocation();

  const windowWidth = window.innerWidth;

  const {
    successfulAllocations,
    email,
    firstName,
    lastName,
    phoneNumber,
    street,
    townOrCity,
    postcode,
    purchaseDateAndTime,
    orderNumber,
    isClubFundraiser,
  } = location.state;

  let orderTotal = 0;
  successfulAllocations.forEach((order) => (orderTotal += order.totalPrice));

  const instantWinPrizes = [];

  for (let successfulAllocation of successfulAllocations) {
    if (successfulAllocation.instantWinPrizes) {
      instantWinPrizes.push(...successfulAllocation.instantWinPrizes);
    }
  }

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <h3 className="mt-3 sm:mb-3 sm:mt-4 text-center text-lg font-semibold shadow-md pb-3">
          Order Confirmation <FontAwesomeIcon icon={faHandHoldingHeart} />
        </h3>

        {/* Order details main container  */}
        <div className="flex flex-col justify-center items-center">
          <div className="mt-2 text-sm sm:text-base px-3 sm:px-0">
            {successfulAllocations.some(
              (successfulAllocation) => successfulAllocation.instantWinPrizes
            ) ? (
              <div className="mb-3">
                <p className="text-lg font-semibold text-center text-lg">
                  CONGRATULATIONS, YOU WON AN INSTANT WIN PRIZE!
                </p>
                <p className="text-sm sm:text-base mb-2 ">
                  Lift Competitions will contact you via the phone number or
                  email listed below in the billing details.
                </p>
              </div>
            ) : null}
            {instantWinPrizes &&
              instantWinPrizes.map((instantWinPrize) => (
                <div
                  key={instantWinPrize.winningTicketNumber}
                  className="flex flex-col md:flex-row bg-white shadow-md rounded-lg mx-auto mb-4 sm:mb-5 w-full md:w-[600px]" // set a specific width as per your requirements
                >
                  <div
                    className={
                      windowWidth < 800
                        ? "flex justify-center px-3 pb-2"
                        : "flex-shrink-0 px-2 sm:mt-4 pb-2"
                    }
                  >
                    <img
                      className="w-7/12 md:w-48 rounded-md"
                      src={instantWinPrize.imageUrl}
                      alt="competition"
                    />
                  </div>
                  <div className="px-3 py-2">
                    <p className="font-semibold text-sm sm:text-base mb-2 border-b-2 pb-1">
                      {instantWinPrize.title}
                    </p>
                    <p className="text-gray-700 mb-2 text-sm sm:text-base">
                      <span className="font-semibold">
                        Winning ticket number:
                      </span>{" "}
                      {instantWinPrize.winningTicketNumber}
                    </p>
                  </div>
                </div>
              ))}

            <p>
              Thank you for your order{" "}
              <span className="font-semibold">{firstName}!</span>
            </p>
            <p>
              Check your email {email} for the same order confirmation summary.
            </p>
            <p>
              <span className="font-semibold">Your order number is: </span>
              {orderNumber}
            </p>
            <p>
              <span className="font-semibold">Ordered at:</span>{" "}
              {purchaseDateAndTime}
            </p>

            <p className="border-b-2 pb-2">
              Please see your competition tickets below
            </p>
          </div>

          {/* For each competition - showcase competition image on lef, right: ticket price, number of tickets purchased, ticket numbers, total */}
          {successfulAllocations.map((purchaseOrder, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row bg-white shadow-md rounded-lg mx-auto mb-3 w-full md:w-[600px]" // set a specific width as per your requirements
            >
              <div
                className={
                  windowWidth < 800
                    ? "flex justify-center px-3 pb-2"
                    : "flex-shrink-0 px-2 sm:mt-4 pb-2"
                }
              >
                <img
                  className="w-7/12 md:w-48 rounded-md"
                  src={purchaseOrder.competitionImageURL}
                  alt="competition"
                />
              </div>
              <div className="px-3 py-2">
                <p className="font-semibold text-sm sm:text-base mb-2 border-b-2 pb-1">
                  {purchaseOrder.competitionTitle}
                </p>
                <p className="text-gray-700 mb-2 text-sm sm:text-base">
                  <span className="font-semibold">Ticket price:</span> $
                  {purchaseOrder.ticketPrice}
                </p>
                <p className="text-gray-700 mb-2 text-sm sm:text-base">
                  <span className="font-semibold">Number of tickets:</span>{" "}
                  {purchaseOrder.allocatedTickets.length}
                </p>
                <p className="text-gray-700 mb-2 text-sm sm:text-base">
                  <span className="font-semibold">Ticket number(s):</span>{" "}
                  {purchaseOrder.allocatedTickets.join(", ")}
                </p>
                <p className="text-gray-700 text-sm sm:text-base">
                  <span className="font-semibold">Total:</span> $
                  {purchaseOrder.totalPrice}
                </p>
              </div>
            </div>
          ))}

          {/* Order total */}
          <div className="text-base sm:text-lg">
            <p className="bg-gray-100 border-2 border-gray-200 rounded-lg w-72 sm:w-96 text-center py-3 shadow-md">
              <span className="font-semibold">Grand total:</span> ${orderTotal}
            </p>
          </div>
        </div>

        {/* Billing details */}
        <div className="flex flex-col align-center items-center gap-2 text-sm sm:text-base mb-2 pb-3">
          <span className="font-semibold">Billing Details</span>
          <span>
            {firstName} {lastName}
          </span>
          <span>{phoneNumber}</span>
          <span>{street}</span>
          <span>{townOrCity}</span>
          <span>{postcode}</span>
          <span>Australia</span>
          <span>{email}</span>
          <span className="text-center text-xs mt-2">
            If you have any questions or concerns with the above information,
            please email us at{" "}
            <span className="font-semibold">info@liftcompetitions.com.au</span>
          </span>
        </div>

        {/* Ending commentary - order supporting SCHF  */}
        {isClubFundraiser ? null : (
          <div className="flex w-full justify-center px-2">
            <div className="flex flex-col text-xs sm:text-sm justify-center align-center items-center px-2 mb-1 w-full sm:w-5/12">
              <img src={SCHF} alt="SCHF" className="my-3" />
              <p>
                Your purchase directly supports children in need through our
                partnered charity:{" "}
                <span className="font-semibold">
                  Sydney Children's Hospitals Foundation
                </span>
                . On behalf of us here at Lift Competitions, we want to thank
                you for your purchase.
              </p>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OrderConfirmation;
