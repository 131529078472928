import React from "react";
import testBanner from "../assets/Win10kBanner1.jpg";
import frequentlyAskedQuestions from "../assets/frequentlyAskedQuestions.jpg";
import howItWorks from "../assets/howItWorks.jpeg";
import rugbyClubs from "../assets/rugbyClubAI.png";
import footballClubs from "../assets/soccerClubAI.png";
import youthClub from "../assets/youthClub.png";
import sportsClub from "../assets/sportsClubAI.png";
import backgroundImage from "../assets/HowToPlayBG.jpg";

const ClubsFundraising = ({ onComponentSelect }) => {
  return (
    <div>
      {/* Banner */}
      <div className="relative bg-cover bg-center w-full">
        <img
          src={backgroundImage}
          alt="Banner"
          className="h-72 md:h-96 w-full"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <div>
            {/* Content for Fundraising */}
            <h1 className="text-gray-600 text-2xl md:text-6xl font-semibold mb-2">
              CLUB LOTTO FUNDRAISING
            </h1>
            <p className="text-gray-600 text-base md:text-2xl font-semibold mb-4">
              Raise money for your club today
            </p>
          </div>
          <button
            className="bg-teal-500 text-sm md:text-base hover:bg-teal-600 text-white font-semibold p-3 rounded"
            onClick={() => window.scrollTo(0, 400)}
          >
            FIND OUT MORE
          </button>
        </div>
      </div>

      {/* Text */}
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-10/12 md:w-1/2 mt-3">
          {/* <h2 className="border-b-2 pb-2">Club Lottery Fundraising</h2> */}
          <p>
            Fundraisers and lotteries are a powerful and sustainable way for
            organisations to raise funds for their core activities or larger
            initiatives. At Lift Competitions our Club Lotto serivce provides an
            easy, low-risk, and low-administration solution to the normally
            costly and time-consuming process of setting up and running a local
            lottery 52 weeks a year.
          </p>
          <p>
            Years of experience in administration, commercial operation, and
            technical know-how of lotteries and fundraisers are combined to
            support your fundraising activities on a bespoke lottery portal.
          </p>
          <p>
            Designed by experienced users, the Lift Competitions Lotto Portal is
            branded to give the feel of your own organisation or club and to
            operate in the most effective manner of balancing economic benefits
            with minimum effort.
          </p>
        </div>
      </div>

      {/* Image links to other pages */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-5 mb-3">
        {/* <img
          src={howItWorks}
          style={{ width: "300px", height: "200px", borderRadius: "5px" }}
          className="transition delay-0 duration-500 hover:shadow-lg hover:cursor-pointer"
          alt="howItWorks"
          onClick={() => onComponentSelect("howItWorks")}
        ></img>

        <img
          src={frequentlyAskedQuestions}
          style={{ width: "300px", height: "200px", borderRadius: "5px" }}
          className="transition delay-0 duration-500 hover:shadow-lg hover:cursor-pointer"
          alt="faqs"
          onClick={() => onComponentSelect("faqs")}
        ></img> */}
        <button
          className="bg-teal-500 text-white font-semibold p-3 shadow-md hover:shadow-lg hover:bg-teal-600 w-40 rounded"
          onClick={() => onComponentSelect("howItWorks")}
        >
          HOW IT WORKS
        </button>
        <button
          className="bg-teal-500 text-white font-semibold p-3 shadow-md hover:shadow-lg hover:bg-teal-600 w-36 rounded"
          onClick={() => onComponentSelect("faqs")}
        >
          FAQS
        </button>
      </div>

      <div className="flex w-full justify-center">
        <hr className="w-1/2" />
      </div>

      {/* Enter email to be contacted */}
      <div className="flex"></div>

      {/* We Support */}
      <div className="">
        <h2 className="text-center py-2">We Can Support</h2>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col justify-center">
              <div className="flex">
                <img
                  src={footballClubs}
                  style={{
                    width: "350px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                  alt="football clubs"
                ></img>
              </div>
              <p className="text-center py-2 font-semibold">Football Clubs</p>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex">
                <img
                  src={rugbyClubs}
                  style={{
                    width: "350px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                  alt="rugby clubs"
                ></img>
              </div>
              <p className="text-center py-2 font-semibold">Rugby Clubs</p>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex">
                <img
                  src={sportsClub}
                  style={{
                    width: "350px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                  alt="sports clubs"
                ></img>
              </div>
              <p className="text-center py-2 font-semibold">Sports Clubs</p>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex">
                <img
                  src={youthClub}
                  style={{
                    width: "350px",
                    height: "200px",
                    borderRadius: "5px",
                  }}
                  alt="youth clubs"
                ></img>
              </div>
              <p className="text-center py-2 font-semibold">Youth Clubs</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubsFundraising;
