import React, { useState, useEffect, useRef } from "react";
import securedWithSquare from "../assets/squareLogo.png";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useShoppingCart } from "../context/useShoppingCart";
import { toast } from "react-toastify";
import { db, app } from "../firebase.config";
import {
  query,
  where,
  collection,
  getDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

//const APPLICATION_ID = "sandbox-sq0idb-TqQX5Hu1AUkLu0oEH7j9hA";
const SANDBOX_APP_ID = process.env.REACT_APP_SQUARE_SANDBOX_APPLICATION_ID;
const PROD_APP_ID = process.env.REACT_APP_SQUARE_PROD_APPLICATION_ID;
const sandboxURL = "https://sandbox.web.squarecdn.com/v1/square.js";
const prodURL = "https://web.squarecdn.com/v1/square.js";

const LOCATION_ID = "L25ZR9VFDA1VM";

const Square = ({
  grandTotal,
  onPaymentSuccess,
  onPaymentError,
  // purchaseOrderInformation,
  email,
  firstName,
  lastName,
  phoneNumber,
  street,
  townOrCity,
  postcode,
  billingDetailsComplete,
  formRefs,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [card, setCard] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("invisible");
  const [cardButtonDisabled, setCardButtonDisabled] = useState(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [isInitialising, setIsInitialising] = useState(false);

  const { cartItems } = useShoppingCart();

  let purchaseOrderInformation = [];

  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const checkSufficientNumberOfAvailableEntriesLeft = async () => {
    let updatedCartItems = [...cartItems];

    for (let cartItem of cartItems) {
      const { competitionID, numberOfEntries, competitionTitle } = cartItem;

      const compRef = doc(
        db,
        "competitions",
        competitionID,
        "entriesCollection",
        "entriesDocument"
      );
      const compSnap = await getDoc(compRef);
      if (compSnap.exists()) {
        const remainingEntryCount = compSnap.data().numberOfEntriesRemaining;
        if (numberOfEntries > remainingEntryCount) {
          // Not removing item from cart anymore - informing user to change number of items in their cart instead
          // updatedCartItems = updatedCartItems.filter(
          //   (item) => item.competitionID !== competitionID
          // );
          // removeFromCart(competitionID);
          toast.error(
            `Unfortunately, there are now only ${remainingEntryCount} entries available for ${competitionTitle}.`,
            { autoClose: 10000 }
          );
          setCheckoutDisabled(false);
        }
      }
    }

    if (updatedCartItems.length === 0) {
      throw new Error("No competitions left in cart.");
    }

    return updatedCartItems;
  };

  const checkIfUserHasAlreadyEnteredCompetition = async (inputCartItems) => {
    let validCartItems = [];

    for (let cartItem of inputCartItems) {
      const {
        competitionID,
        numberOfEntries,
        maxNumberOfEntries,
        competitionTitle,
      } = cartItem;
      const userExistingEntryNumbers = [];

      const userQuery = query(
        collection(db, "competitions", competitionID, "userPurchases"),
        where("email", "==", email)
      );
      const userSnap = await getDocs(userQuery);

      if (!userSnap.empty) {
        userSnap.forEach((doc) => {
          userExistingEntryNumbers.push(...doc.data().entryNumbers);
        });
      }

      if (
        userExistingEntryNumbers.length + numberOfEntries <=
        maxNumberOfEntries
      ) {
        validCartItems.push(cartItem); // Keep the cartItem if it's valid
      } else {
        toast.error(
          `You have already purchased ${userExistingEntryNumbers.length} entries for competition 
          "${competitionTitle}". Max number of entries per person is ${maxNumberOfEntries}.`,
          { autoClose: 10000 }
        );
        //removeFromCart(competitionID);
        setCheckoutDisabled(false);

        // Adjust the grandTotal
        //grandTotal = grandTotal - cartItem.totalPrice;
      }
    }

    //setCartItems(validCartItems); // Set the cart items to the new valid list
    //return validCartItems.length > 0;
    return validCartItems;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = sandboxURL;
    script.id = "webPayment";
    script.onload = () => {
      setIsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // let card;
    if (isLoaded) {
      //const payments = window.Square.payments(PROD_APP_ID, LOCATION_ID);
      const payments = window.Square.payments(SANDBOX_APP_ID, LOCATION_ID);
      try {
        initializeCard(payments);
        initializeGooglePay(payments, formRefs);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    }
  }, [isLoaded]);

  const termsAndConditionsRef = useRef(null);

  async function initializeCard(payments) {
    // If the form is currently being initialized, return early
    if (isInitialising) {
      return;
    }

    // Set isInitializing to true
    setIsInitialising(true);

    const card = await payments.card();
    await card.attach("#card-container");
    setCard(card);

    // Set isInitializing back to false
    setIsInitialising(false);

    return card;
  }

  // Need to supply refs to googlePayPayment (email, firstName, etc.), hence the reason for additional payment function
  async function createGooglePayPayment(
    token,
    purchaseOrderInformation,
    email,
    firstName,
    lastName,
    phoneNumber,
    street,
    townOrCity,
    postcode,
    grandTotal
  ) {
    const body = JSON.stringify({
      purchaseOrderInformation,
      sourceId: token,
      email,
      firstName,
      lastName,
      phoneNumber,
      street,
      townOrCity,
      postcode,
      grandTotal,
    });

    console.log(body);

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://handlepurchase-handlepurchase-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();
      console.log(data);

      if (data.error) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  }

  async function createPayment(token, purchaseOrderInformation) {
    const body = JSON.stringify({
      purchaseOrderInformation,
      sourceId: token,
      email,
      firstName,
      lastName,
      phoneNumber,
      street,
      townOrCity,
      postcode,
      grandTotal,
    });

    console.log(body);

    try {
      // Send a POST request to your function
      const response = await fetch(
        `https://handlepurchase-handlepurchase-ybv5mc22oq-ts.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      // Parse the response body
      const data = await response.json();
      console.log(data);

      if (data.error) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  }

  function buildPaymentRequest(payments, grandTotal) {
    return payments.paymentRequest({
      countryCode: "AU",
      currencyCode: "AUD",
      total: {
        //amount: grandTotal.toString(),
        amount: grandTotal.toString(),
        label: "Total",
      },
    });
  }

  async function initializeGooglePay(payments, formRefs) {
    const paymentRequest = buildPaymentRequest(payments, grandTotal);

    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach("#google-pay-button");

    document
      .getElementById("google-pay-button")
      .addEventListener("click", async () => {
        // Need to use refs as Google Pay uses an event listener
        const currentFirstName = formRefs.firstNameRef.current.value;
        const currentLastName = formRefs.lastNameRef.current.value;
        const currentEmail = formRefs.emailRef.current.value;
        const currentPhoneNumber = formRefs.phoneNumberRef.current.value;
        const currentStreet = formRefs.streetRef.current.value;
        const currentTownOrCity = formRefs.townOrCityRef.current.value;
        const currentPostcode = formRefs.postcodeRef.current.value;
        const isTermsAndConditionsAgreed =
          termsAndConditionsRef.current.checked;

        if (!isTermsAndConditionsAgreed) {
          toast.error(
            "You need to agree to the Terms and Conditions to check out.",
            { autoClose: 4000 }
          );
          return;
        }

        // Check if billing details are complete
        if (
          currentFirstName !== "" &&
          currentLastName !== "" &&
          isEmail(currentEmail) &&
          currentPhoneNumber !== "" &&
          currentStreet !== "" &&
          currentTownOrCity !== "" &&
          currentPostcode !== ""
        ) {
          try {
            //setShowLoading(true);
            setCheckoutDisabled(true);

            // Check if sufficient number of entries left for all competitions in cart
            const itemsAfterAvailableCheck =
              await checkSufficientNumberOfAvailableEntriesLeft();

            // Check if user has already entered competition
            const validCartItems =
              await checkIfUserHasAlreadyEnteredCompetition(
                itemsAfterAvailableCheck
              );

            // If nothing left in the cart, exit early
            if (validCartItems.length === 0) {
              setShowLoading(false);
              return;
            }

            console.log("CartItems: ", validCartItems);
            // Update purchase orderInformation based upon updated cartItems from "checkIfUserHasAlreadyEnteredCompetition"
            for (let item of validCartItems) {
              purchaseOrderInformation.push({
                competitionID: item.competitionID,
                competitionTitle: item.competitionTitle,
                numberOfEntries: item.numberOfEntries,
                packageLevel: item.packageLevel,
                packagePrice: item.packagePrice,
              });

              // Update total price based upon updated cartItems
              //grandTotal += item.totalPrice;
            }

            const tokenResult = await googlePay.tokenize();
            if (tokenResult.status === "OK") {
              console.log(`Payment token is ${tokenResult.token}`);
              displayPaymentResults("SUCCESS");
              const paymentResults = await createGooglePayPayment(
                tokenResult.token,
                purchaseOrderInformation,
                currentEmail,
                currentFirstName,
                currentLastName,
                currentPhoneNumber,
                currentStreet,
                currentTownOrCity,
                currentPostcode,
                grandTotal
              );
              console.debug("Payment Success", paymentResults);
              onPaymentSuccess(paymentResults, validCartItems);
            } else {
              let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
              if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                  tokenResult.errors
                )}`;
              }
              throw new Error(errorMessage);
            }
          } catch (error) {
            setCheckoutDisabled(false);
            setShowLoading(false);
            setPaymentStatus("FAILURE");
            console.error(error.message);
            onPaymentError(error);
          }
        } else {
          toast.error("Need to complete billing details before checking out.", {
            autoClose: 2000,
          });
        }
      });

    return googlePay;
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById("payment-status-container");
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }

    statusContainer.style.visibility = "visible";
  }

  async function handlePaymentMethodSubmission(event) {
    event.preventDefault();

    if (!card) {
      console.log("no card");
      return;
    }

    if (!billingDetailsComplete) {
      // Check if billing details are complete
      toast.error("You must complete all billing details.", {
        autoClose: 3000,
      });
      return;
    }

    setShowLoading(true);
    setCheckoutDisabled(true);

    // Check if sufficient number of entries left for all competitions in cart
    const itemsAfterAvailableCheck =
      await checkSufficientNumberOfAvailableEntriesLeft();

    // Check if user has already entered competition
    const validCartItems = await checkIfUserHasAlreadyEnteredCompetition(
      itemsAfterAvailableCheck
    );

    // If nothing left in the cart, exit early
    if (validCartItems.length === 0) {
      setShowLoading(false);
      return;
    }

    console.log("CartItems: ", validCartItems);

    // If both of the above checks have passed, proceed with ahndling payment
    // Update purchase orderInformation based upon updated cartItems from "checkIfUserHasAlreadyEnteredCompetition"
    // Update purchase orderInformation based on the result of the above checks
    for (let item of validCartItems) {
      purchaseOrderInformation.push({
        competitionID: item.competitionID,
        competitionTitle: item.competitionTitle,
        numberOfEntries: item.numberOfEntries,
        packageLevel: item.packageLevel,
        packagePrice: item.packagePrice,
      });

      // Update total price based upon updated cartItems
      //grandTotal += item.totalPrice;
    }

    try {
      const token = await tokenize(card);
      //const verificationToken = await verifyBuyer(payments, token);

      const paymentResults = await createPayment(
        token,
        purchaseOrderInformation,
        email,
        firstName,
        lastName,
        phoneNumber,
        street,
        townOrCity,
        postcode
      );
      setPaymentStatus("SUCCESS");
      console.debug("Payment Success", paymentResults);

      onPaymentSuccess(paymentResults, validCartItems);
    } catch (error) {
      setCheckoutDisabled(false);
      setShowLoading(false);
      setPaymentStatus("FAILURE");
      console.error(error.message);
      onPaymentError(error);
    }
  }

  return (
    <div className="px-3">
      <form id="payment-form">
        <div className="flex justify-center my-2">
          <div id="google-pay-button"></div>
        </div>

        <div id="card-container"></div>
        <div className="flex gap-2 px-4">
          <input
            type="checkbox"
            className="mb-3"
            ref={termsAndConditionsRef}
            id="termsAndConditions"
            onChange={() => setCheckoutDisabled(!checkoutDisabled)}
          ></input>
          <label htmlFor="termsAndConditions" className="text-xs sm:text-sm">
            I have read and agree to the{" "}
            <Link to="/privacy-policy">Privacy Policy</Link> and the{" "}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>{" "}
          </label>
        </div>
        <div className="flex justify-center mt-3">
          <button
            disabled={checkoutDisabled}
            className={
              checkoutDisabled
                ? "bg-gray-400 w-7/12 py-2 mt-2 rounded-sm text-white font-semibold transition duration-200 ease-in-out border-gray-300/80 shadow-inner shadow-md"
                : "bg-teal-500 w-7/12 py-2 mt-2 cursor-pointer rounded-sm text-white font-semibold transition duration-300 ease-in-out hover:bg-teal-600 border-gray-300/80 shadow-inner shadow-md"
            }
            onClick={(event) => handlePaymentMethodSubmission(event)}
          >
            {showLoading ? (
              <div className="flex justify-center gap-2 px-2">
                <BeatLoader color="white" />{" "}
                <span className="text-xs">
                  Do not refresh or close your browser..
                </span>
              </div>
            ) : (
              `Checkout $${grandTotal}`
            )}
          </button>
        </div>
      </form>
      <div id="payment-status-container" className={paymentStatus}></div>
      <div className="flex items-center justify-center align-center mb-3 mt-3 sm:mt-0">
        <span className="text-xs font-semibold">Payment secured with </span>
        <img src={securedWithSquare} width="120" alt="securedWithSquare" />
      </div>
    </div>
  );
};

export default Square;
