import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockAlt,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { app } from "../firebase.config";
import Footer from "../components/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      toast.success(`Email has been sent to ${email}`);
      navigate("/sign-in");
    } catch (error) {
      toast.error("Could not send reset email");
    }
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <h1 className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3">
          Forgot Password
        </h1>
        {/* Logon Card */}
        <div className="flex justify-center mt-4 sm:mt-5 mb-4 sm:mb-0">
          <div className="border-2 rounded-2xl border-gray-300/80 py-4 px-3 shadow-inner shadow-md bg-gray-50">
            {/* Card icon */}
            <div className="text-center">
              <FontAwesomeIcon
                icon={faUnlockAlt}
                color="black"
                size="3x"
                className="mb-2"
              />

              {/* Heading */}
              <p className="mt-2 text-lg font-semibold"></p>
            </div>

            {/* Logon Fields */}
            <div className="flex">
              <form onSubmit={onSubmit}>
                <div className="flex flex-col py-2 w-64 mb-2">
                  <label className="text-sm sm:text-base">Email</label>
                  <input
                    className="border p-2 text-sm sm:text-base"
                    type="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Enter email"
                  ></input>
                </div>

                <div className="text-center rounded-md py-2 mt-3 border-2 border-gray-300 duration-300 hover:bg-gray-300 hover:cursor-pointer mb-3">
                  <button className="text-sm sm:text-base">
                    Send Reset Link{" "}
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      color="grey"
                    />
                  </button>
                </div>
                {/* Sign Up Instead */}
                <div
                  onClick={() => navigate("/sign-in")}
                  className="text-center py-2 mt-5 border-gray-300 duration-300 hover:font-semibold hover:cursor-pointer mb-3"
                >
                  <button className="text-base text-teal-400">
                    Sign In Instead{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
