import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import AdminCreateNewCompetition from "../components/AdminCreateNewCompetition";
import AdminViewCompetitions from "../components/AdminViewCompetitions";

const Admin = () => {
  const [isSelected, setIsSelected] = useState("create");

  const renderComponent = () => {
    switch (isSelected) {
      case "create":
        return <AdminCreateNewCompetition />;
      case "view":
        return <AdminViewCompetitions />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* <NavBar /> */}
      {/* <NavBar /> */}
      <div className="flex justify-center mt-3 bg-white shadow-md pb-2">
        <h1 className="text-lg font-semibold tracking-wider">
          LIFT COMPETITIONS | <span className="font-normal">ADMIN</span>{" "}
          <FontAwesomeIcon icon={faUserAlt} />
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="border-b-2 w-1/3 mb-3"></div>
      </div>
      <div className="flex justify-center gap-5">
        <button
          className={
            isSelected === "create"
              ? "py-3 px-2 bg-teal-500 rounded-md text-white font-semibold shadow-md"
              : "py-3 px-2 bg-gray-400 rounded-md text-white font-semibold hover:bg-teal-600 hover:shadow-lg"
          }
          onClick={() => setIsSelected("create")}
        >
          Create Competition
        </button>
        <button
          className={
            isSelected === "view"
              ? "py-3 px-2 bg-teal-600 rounded-md text-white font-semibold shadow-md"
              : "py-3 px-2 bg-gray-400 rounded-md text-white font-semibold hover:bg-teal-500 hover:shadow-lg"
          }
          onClick={() => setIsSelected("view")}
        >
          View Competitions
        </button>
      </div>

      <div>{renderComponent()}</div>
    </>
  );
};

export default Admin;
