import React, { useEffect } from "react";
import One from "../assets/one.png";
import Two from "../assets/two.png";
import Three from "../assets/three.png";

const ClubsHowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-10/12 md:w-1/2 border-b-2 pb-3">
          <h4 className="mb-3 mt-2 font-semibold">How It Works</h4>
          <p>
            Once onboarded with Lift Competitions, your fundraiser runs
            seamlessly each week on your dedicated webpage. Funds raised are
            solely for your organisation, distinct from any other fundraiser.
            Entrants enjoy clear play options, including the choice of repeat
            plays.
          </p>
          <p>
            Responsible limits on plays per login ensure fair participation. You
            determine the play price, the date of commencement and finish of
            lotto, aligning with what suits your organisation and community
            best. Tailor your fundraiser with bespoke instant wins and rollover
            rate options. Customise second-tier prizes to fit your
            circumstances.
          </p>
        </div>
      </div>

      <div className="py-4 sm:py-5">
        <h1 className="text-lg font-semibold text-gray-900 text-center mb-3 sm:mb-5">
          It's as simple as..
        </h1>
        <div className="flex justify-center"></div>
        <div className="flex-container ">
          <div className="mb-4 sm:mb-3 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={One} alt="competition" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">Sign Up</p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              Lift Competitions will take you through a simple onboarding
              process to gather your fundraiser details.
            </p>
          </div>
          <div className="mb-4 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={Two} alt="tickets" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">
              We Build Your Portal
            </p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              We provide you with a custom URL and QR code for your bespoke
              fundraiser.
            </p>
          </div>
          <div className="mb-4 sm:mb-3 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={Three} alt="charity" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">
              Fundraising & Promotion
            </p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              Share custom URL wiith the community online, offline and get your
              community involved.
            </p>
          </div>
        </div>
      </div>

      {/* What We Need From You */}
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-10/12 md:w-1/2 border-t-2 pt-4">
          <h4 className="font-semibold">What We Need From You</h4>
          <p>
            Lift Competitions will walk your club through a simple onboarding
            template to gather some key details for your fundraiser. These
            details include a main prize, any number of 'instant win prizes',
            draw date, number of competition tickets and price of each ticket.
          </p>
          <p>
            Lift Competitions will also require the club to complete a signatory
            'authority to fundraise' (included in this onboarding template)
            which basically states that the club is authorising Lift
            Competitions to raise funds via the Lift Competitions portal.
          </p>
        </div>
      </div>

      {/* What Do We Do */}
      <div className="flex w-full justify-center">
        <div className="flex flex-col w-10/12 md:w-1/2 border-t-2 pt-4">
          <h4 className="font-semibold">What We Do</h4>
          <p>
            Once we have a completed onboarding template, we start by building
            your custom fundraiser based upon the prize details provided. Once
            up and running, Lift Competitions will provide your club with your
            designated fundraiser URL and a QR code (if desired).
          </p>
          <p>
            Your fundraiser will be online and available 24/7 via the designated
            URL provided by Lift Competitions. Entrants can also find the
            fundraiser manually through the Lift Competitions site at
            https://liftcompetitions.com.au
          </p>
          <p>
            Once the fundraiser draw date has been reached, or the fundraiser
            has sold out, Lift Competitions will provide the club with the
            entrant list for the fundraiser which will include the entrant name,
            ticket number and contact details. This entrant list is also
            available for public access (excluding the entrant email and phone
            number) via the Lift Competitions 'entrant list' page that can be
            found in the Lift Competitions footer.
          </p>
          <p>
            Once a prize winner has been allocated, Lift Competitions will
            transfer the club funds directly to the nominated club bank account
            (minus the transfer fees from Square).
          </p>
          <p></p>
          <p className="mb-4"></p>
        </div>
      </div>
    </div>
  );
};

export default ClubsHowItWorks;
