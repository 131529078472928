import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";
import useFetchCompetition from "../hooks/useFetchCompetition";
import CompetitionLeftColumn from "../components/CompetitionLeftColumn";
import Footer from "../components/Footer";
import ImageSlider from "../components/ImageSlider";
import Spinner from "../components/Spinner";
//import square from "../assets/squarePayment.jpeg";
import square from "../assets/squareLogo.png";
import SCHF from "../assets/SCHF.svg";

const Competition = () => {
  const navigate = useNavigate();

  const windowWidth = window.innerWidth;

  const { competition, numberOfEntriesRemaining, isLoading, competitionID } =
    useFetchCompetition();

  const [numberOfTickets, setNumberOfTickets] = useState({});

  const [cartData, setCartData] = useState({});

  const getNumberOfTickets = (tickets) => {
    setNumberOfTickets(tickets);
    setCartData({ ...competition, numberOfTickets });
  };

  if (competition) {
    return (
      <div className="force-footer">
        <div className="main-content">
          {/* <NavBar /> */}
          <main className="px-3 sm:px-2 sm:p-0 mb-4">
            {/* Two main columns of data - left: info, right: images  */}
            {windowWidth < 1000 ? (
              <div className="mt-2 no-underline">
                <Link
                  to="/competitions"
                  className="text-sm no-underline text-black hover:underline hover:font-semibold text-gray-800"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to competitions
                </Link>
              </div>
            ) : (
              <div></div>
            )}
            {/* Main Container */}
            <div className="flex flex-col-reverse lg:flex-row justify-center">
              <CompetitionLeftColumn
                competition={competition}
                isLoading={isLoading}
                competitionID={competitionID}
                numberOfEntriesRemaining={numberOfEntriesRemaining}
              />
              <div className="pl-0 sm:pl-10 mt-3 w-full mb-4 sm:mb-0 lg:w-2/5 xl:max-w-lg">
                <ImageSlider
                  isLoading={isLoading}
                  images={competition.imageURLs}
                />
                {windowWidth > 1000 ? (
                  <div>
                    <div className="text-sm mt-4">
                      By entering this competition, you have confirmed that you
                      have read and agree to our{" "}
                      <Link to="/terms-and-conditions">
                        Terms and Conditions
                      </Link>
                      . Please note that the manufacturers involved in this
                      competition do not endorse this competition and are in no
                      way associated with Lift Competitions.
                    </div>
                    <div className="flex items-center justify-center mt-2">
                      <span className="font-semibold text-xs">
                        Payments secured with
                      </span>
                      <img src={square} width="150" alt="squarePayment" />
                    </div>

                    {competition.isClubFundraiser === true ? null : (
                      <div className="pt-2 pb-3 pl-2 flex items-center justify-center gap-4">
                        <p className="text-xs font-semibold">
                          Every purchased package supports
                        </p>
                        {/* <p className="text-xs sm:text-sm">
                          <FontAwesomeIcon icon={faHandHoldingHeart} /> Every
                          ticket purchased supports
                        </p> */}
                        <img
                          src={SCHF}
                          width={windowWidth < 1000 ? "100" : "150"}
                          alt="SCHFCharityImage"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default Competition;
