import React, { useState, useEffect } from "react";
import PreviousOrderItem from "./PreviousOrderItem";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "react-bootstrap";

const PreviousOrder = ({
  orderNumber,
  showPreviousOrderList,
  previousOrders,
}) => {
  const [orderData, setOrderData] = useState([]);

  let newArr = [];

  const getOrderNumberCorrespondingOrderData = async () => {
    newArr = previousOrders.filter(
      (prevOrder) => prevOrder.orderNumber === orderNumber
    );
    setOrderData(newArr);
  };

  useEffect(() => {
    getOrderNumberCorrespondingOrderData();
  }, []);

  return (
    <div>
      {orderData.length > 0 ? (
        <div className="p-1">
          <div className="px-3 pb-4 pt-1 sm:px-5 sm:py-3 text-sm sm:text-base">
            <button
              onClick={showPreviousOrderList}
              className="hover:font-semibold hover:underline"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back to previous orders
            </button>
          </div>
          <div className="">
            <p className="pl-3 sm:pl-0 text-sm sm:text-base sm:text-center">
              <span className="font-semibold">Order Number:</span> {orderNumber}
            </p>
            <p className="pl-3 sm:pl-0 text-sm sm:text-base sm:text-center">
              <span className="font-semibold">Your order was placed on:</span>{" "}
              {orderData[0].date}
            </p>
          </div>
          <div className="flex w-full justify-center mb-2 sm:mb-5">
            <div className="p-2 shadow-md">
              {orderData.map((orderItemData, index) => (
                <PreviousOrderItem orderItemData={orderItemData} key={index} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default PreviousOrder;
