import React, { useState, useEffect } from "react";

const ImageSlider = ({ images, imgClassName }) => {
  const [current, setCurrent] = useState(0); // Current image index in the array

  // Function to go to the next image
  const goNext = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  // Go to a specific index
  const goToSlide = (index) => {
    setCurrent(index);
  };

  // Auto scroll to the next image every 4 seconds
  useEffect(() => {
    if (images.length > 1) {
      const timer = setInterval(() => {
        goNext();
      }, 4000);

      // Clear interval on unmount
      return () => clearInterval(timer);
    }
  }, [current, images]);

  return (
    <div className="flex flex-col items-center justify-center">
      <img
        src={images[current]}
        alt="carousel-item"
        className="w-72 sm:w-6/12 lg:w-10/12 rounded-md"
      />
      <div className="flex mt-4 justify-center space-x-2">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            onClick={() => goToSlide(index)}
            alt=""
            className={`h-12 w-auto object-cover cursor-pointer opacity-50 hover:opacity-100 transition-opacity duration-200 ${
              current === index ? "border-2 border-teal-500 opacity-100" : ""
            }`} // Add a border and full opacity if this image is currently selected, otherwise give it 50% opacity. Also add a hover effect.
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
