import React, { useEffect, useState } from "react";
import { db, app } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";
import { BeatLoader } from "react-spinners";

const PreviousOrderItem = ({ orderItemData }) => {
  const [compImageURL, setCompImageURL] = useState("");
  const [compTitle, setCompTitle] = useState("");

  const getCompImageURL = async () => {
    const docRef = doc(db, "competitions", orderItemData.competitionID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setCompImageURL(docSnap.data().imageURLs[0]);
      setCompTitle(docSnap.data().competitionTitle);
    }
  };

  useEffect(() => {
    getCompImageURL();
  }, []);

  return (
    <div className="flex justify-center">
      {/* Competition image */}
      <div className="basis-4/12 md:basis-1/12">
        {compImageURL ? (
          <img
            src={compImageURL}
            alt="competition title"
            className="rounded-xl"
          />
        ) : (
          <BeatLoader color="#5eead4" />
        )}
      </div>

      {/* Order competition information  */}
      <div className="px-3 py-0 sm:py-2 basis-8/12 md:basis-4/12">
        <div className="font-semibold mb-2 text-sm border-b-2 pb-2">
          {compTitle && compTitle}
        </div>
        {/* <div>Purchase Date: {orderItemData.date}</div> */}
        {/* <div>Ticket numbers: {}</div> */}
        <div className="text-sm py-1">
          <span className="font-semibold">Ticket price:</span> $
          {orderItemData.ticketPrice}
        </div>
        <div className="text-sm py-1">
          <span className="font-semibold">Number of tickets:</span>{" "}
          {Object.values(orderItemData.ticketNumbers).length}
        </div>
        <div className="text-sm py-1">
          <span className="font-semibold">Ticket number(s):</span>{" "}
          {Object.values(orderItemData.ticketNumbers).map(
            (ticketNumber, index) => (
              <span key={index}>
                {ticketNumber}
                {/* Remove the , on the last ticket number */}
                {index !== Object.values(orderItemData.ticketNumbers).length - 1
                  ? ","
                  : ""}{" "}
              </span>
            )
          )}
        </div>
        <div className="text-sm py-1">
          <span className="font-semibold">Total:</span> $
          {orderItemData.totalPrice}
        </div>
        <div className="border-b-2 border-gray-200 my-3 sm:mt-2"></div>
      </div>
    </div>
  );
};

export default PreviousOrderItem;
