import React from "react";
import One from "../assets/one.png";
import Two from "../assets/two.png";
import Three from "../assets/three.png";

const HowItWorks = () => {
  return (
    <>
      {/* <div className="py-4 sm:py-5 bg-gradient-to-l from-gray-100 to-gray-200 htp-background-image"> */}
      <div className="py-4 sm:py-5 htp-background-image shadow-md">
        <h1 className="text-lg font-semibold text-gray-900 text-center mb-3 sm:mb-5">
          It's as simple as..
        </h1>
        <div className="flex justify-center"></div>
        <div className="flex-container ">
          <div className="mb-4 sm:mb-3 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={One} alt="competition" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">
              Choose a Competition
            </p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              Select one of our active competitions to view the prize details,
              ticket price and draw date.
            </p>
          </div>
          <div className="mb-4 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={Two} alt="tickets" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">
              Grab Your Tickets
            </p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              Grab your tickets and you're in with a chance! Each draw will be
              livestreamed on our{" "}
              <a
                href="https://www.facebook.com/liftcompetitions"
                target="_blank"
                rel="noreferrer"
                className="text-black hover:font-semibold"
              >
                Facebook
              </a>{" "}
              page.
            </p>
          </div>
          <div className="mb-4 sm:mb-3 mx-4 text-center w-7/12 sm:max-w-xs border-2 rounded-2xl border-gray-300/80 py-4 px-2 shadow-inner shadow-md bg-gray-50 transition duration-500 hover:border-teal-300">
            <div className="flex justify-center">
              <img src={Three} alt="charity" width="60" />
            </div>

            {/* Heading */}
            <p className="mt-2 text-base sm:text-lg font-semibold">
              Support a Great Cause
            </p>

            {/* Text */}
            <p className="text-sm sm:text-base">
              Every ticket purchased contributes to the Sydney Children's
              Hospitals Foundation, helping children in need.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
