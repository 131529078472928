import React from "react";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarkCircle,
  faWarning,
  faFireAlt,
  faBoltLightning,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import calendarIcon from "../assets/calendarIcon.png";
import tickets from "../assets/tickets.png";

function formatDate(dateString) {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}

const ActiveCompetitionCard = ({ competition, isLoading }) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <BeatLoader />;
  } else {
    const {
      competitionTitle,
      discountedPriceOfEntry,
      drawDate,
      imageURLs,
      numberOfEntries,
      numberOfEntriesRemaining,
      priceOfEntry,
      instantWinPrizeDetails,
    } = competition.data;

    console.log(competition.data);

    const formattedDate = formatDate(drawDate);

    return (
      <>
        <div className="flex justify-center">
          {/* Card main container */}
          <div
            // className="bg-gray-100 border-2 rounded-md border-gray-300/80 shadow-inner shadow-md transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 w-64 sm:w-72 cursor-pointer mb-4"
            className={
              numberOfEntriesRemaining && numberOfEntriesRemaining > 0
                ? "bg-gray-100 border-2 rounded-md border-gray-300/80 shadow-inner shadow-md transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 mx-3 w-64 sm:w-72 cursor-pointer mb-4 hover:text-yellow-500"
                : "bg-gray-100 border-2 rounded-md border-rose-500/80 shadow-inner shadow-md transition duration-500 ease-in-out mx-3 w-64 sm:w-72 cursor-pointer mb-4"
            }
            onClick={() => navigate(`/competitions/${competition.id}`)}
          >
            <img
              src={imageURLs[0]}
              alt={competition.competitionTitle}
              className="h-48 sm:h-56 w-full "
            />
            {numberOfEntriesRemaining === 0 ? (
              <div className="flex justify-center mt-3">
                <div className="text-rose-500 font-semibold mb-2">
                  <FontAwesomeIcon icon={faXmarkCircle} /> SOLD OUT
                </div>
              </div>
            ) : numberOfEntries / (numberOfEntries - numberOfEntriesRemaining) <
              1.1 ? (
              <div className="flex justify-center mt-3">
                <div className="text-orange-500 font-semibold mb-2">
                  <FontAwesomeIcon icon={faWarning} /> ALMOST SOLD OUT
                </div>
              </div>
            ) : instantWinPrizeDetails ? (
              <div className="flex justify-center mt-3">
                <div className="text-teal-500 font-semibold mb-2">
                  {instantWinPrizeDetails.length > 1 ? (
                    <span>
                      <FontAwesomeIcon icon={faBoltLightning} />{" "}
                      {instantWinPrizeDetails.length} INSTANT WIN PRIZES
                    </span>
                  ) : instantWinPrizeDetails === 1 ? (
                    "INSTANT WIN PRIZE"
                  ) : null}
                </div>
              </div>
            ) : numberOfEntries < 500 ? (
              <div className="flex justify-center mt-3">
                <div className="text-teal-500 font-semibold mb-2">
                  <FontAwesomeIcon icon={faFireAlt} /> LOW ODDS
                </div>
              </div>
            ) : null}
            <div className="px-3 pt-2 pb-1">
              <p className="text-sm sm:text-base border-b-2 border-teal-400/50 pb-2 font-semibold">
                {competitionTitle}
              </p>
              {/* <p className="text-yellow-500 text sm:text-lg sm:text-xl font-semibold">
                ${priceOfEntry}
              </p> */}
              <div className="flex font-semibold gap-1 align-center items-center pb-1">
                <img src={calendarIcon} alt="calendarIcon" width="35px" />
                <span>{formattedDate}</span>
              </div>
              <div className="flex font-semibold gap-1 align-center items-center py-1">
                <img src={tickets} alt="ticketsIcon" width="40px" />
                <span>{numberOfEntries}</span>
              </div>
              {/* {instantWinPrizeDetails ? (
                <div className="flex gap-1 align-center items-center py-1">
                  <img src={lightningBolt} alt="lightningBolt" width="40px" />
                  <span>
                    {instantWinPrizeDetails.length} instant win prizes
                  </span>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ActiveCompetitionCard;
