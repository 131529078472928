import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchCompetitions from "../hooks/useFetchCompetitions";
import { BeatLoader } from "react-spinners";
import { parseISO, differenceInDays, formatDate } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFire,
  faFireAlt,
} from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
  const navigate = useNavigate();
  const [activeCompetitionsWithBanner, setActiveCompetitionsWithBanner] =
    useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClick = () => {
    navigate(
      `/competitions/${activeCompetitionsWithBanner[currentImageIndex].competitionID}`
    );
  };

  const { competitions, loading } = useFetchCompetitions();

  const windowWidth = window.innerWidth;

  //const formattedDate = formatDate(competition.drawDate);

  function numberOfDaysUntilDraw(competitionDateString) {
    const competitionDate = parseISO(competitionDateString);
    const currentDate = new Date();

    const daysDifference = differenceInDays(competitionDate, currentDate);

    return daysDifference;
  }

  useEffect(() => {
    //setLoading(true);
    if (competitions) {
      competitions.forEach((competition) => {
        if (
          competition.data.bannerURL &&
          competition.data.isActive === true &&
          competition.data.isFeatured
        )
          setActiveCompetitionsWithBanner((prevState) => {
            return [
              ...prevState,
              {
                bannerURL: competition.data.bannerURL,
                competitionTitle: competition.data.competitionTitle,
                competitionID: competition.id,
                imageURLs: competition.data.imageURLs,
                drawDate: competition.data.drawDate,
                showDrawDateBanner:
                  numberOfDaysUntilDraw(competition.data.drawDate) < 10,
              },
            ];
          });
      });
    }
  }, [competitions]);

  useEffect(() => {
    // Start the interval if there are active competitions with banners.
    if (activeCompetitionsWithBanner.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % activeCompetitionsWithBanner.length
        );
      }, 10000); // Change image every 10 seconds

      // Cleanup: clear the interval when the component unmounts.
      return () => clearInterval(interval);
    }
  }, [activeCompetitionsWithBanner]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center align-center items-center py-5">
          <BeatLoader color="#5EEAD4" size={20} />
        </div>
      ) : (
        activeCompetitionsWithBanner.length > 0 && (
          <div className="banner-container">
            <img
              src={
                windowWidth > 750
                  ? activeCompetitionsWithBanner[currentImageIndex].bannerURL
                  : activeCompetitionsWithBanner[currentImageIndex].imageURLs[0]
              }
              //src={DesktopBanner}
              className={`banner-image`}
              alt="Banner"
              onClick={handleClick}
            />

            {activeCompetitionsWithBanner[currentImageIndex]
              .showDrawDateBanner && (
              <div className="moving-banner">
                <span className="moving-banner-text">
                  <FontAwesomeIcon icon={faCircleExclamation} />{" "}
                  {
                    activeCompetitionsWithBanner[currentImageIndex]
                      .competitionTitle
                  }
                  {"  "}
                  draw happening
                  {numberOfDaysUntilDraw(
                    activeCompetitionsWithBanner[currentImageIndex].drawDate
                  ) === 1
                    ? " tomorrow, BE QUICK"
                    : ` in ${numberOfDaysUntilDraw(
                        activeCompetitionsWithBanner[currentImageIndex].drawDate
                      )} days`}{" "}
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </span>
              </div>
            )}

            <div className="dots-container">
              {activeCompetitionsWithBanner.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${
                    index === currentImageIndex ? "active" : ""
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                ></span>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Banner;
