import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { app, db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import PurchaseHistory from "../components/PurchaseHistory";
import { useShoppingCart } from "../context/useShoppingCart";

const MyAccount = () => {
  const auth = getAuth(app);

  const { clearCart } = useShoppingCart();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    street: "",
    townOrCity: "",
    postcode: "",
    phoneNumber: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAccountDetails, setShowAccountDetails] = useState(true);
  const [previousOrders, setPreviousOrders] = useState([]);

  const {
    firstName,
    lastName,
    email,
    street,
    townOrCity,
    postcode,
    phoneNumber,
  } = formData;

  const navigate = useNavigate();

  // Variable to store all competition IDs
  let competitionIDs = [];

  const logoutUser = () => {
    auth.signOut();
    //clearCart();
    navigate("/");
    toast.success("You have been logged out!", { autoClose: 1000 });
  };

  const fetchCompetitionIDs = async () => {
    // Get competitionIDs
    const compQ = query(collection(db, "competitions"));
    const compQSnap = await getDocs(compQ);
    compQSnap.forEach((doc) => {
      competitionIDs.push(doc.id);
    });
  };

  const fetchUserPreviousOrders = async () => {
    if (auth.currentUser && previousOrders.length === 0) {
      const user = auth.currentUser;
      //console.log(user);

      await fetchCompetitionIDs().then(async () => {
        // Get competitions which user has purchased tickets in and set to previousOrders state
        competitionIDs.forEach(async (competitionID) => {
          const userCompTicketQ = query(
            collection(db, competitionID),
            where("ticketHolderUID", "==", user.uid)
          );

          const userPurchaseRef = query(
            query(
              collection(db, "competitions", competitionID, "userPurchases"),
              where("email", "==", user.email)
            )
          );
          const userPurchaseSnap = await getDocs(userPurchaseRef);
          userPurchaseSnap.forEach((userPurchase) => {
            //console.log(userPurchase.data());
            setPreviousOrders((prevOrder) => {
              return [...prevOrder, userPurchase.data()];
            });
          });
        });
        setShowAccountDetails(false);
      });
    } else if (previousOrders.length >= 0) {
      setShowAccountDetails(false);
    } else {
      toast.error("Something went wrong - you need to be signed in.");
      navigate("/");
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      const user = auth.currentUser;
      setShowSpinner(true);

      const fetchUserAccountInformation = async () => {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormData({
            firstName: docSnap.data().firstName,
            lastName: docSnap.data().lastName,
            phoneNumber: docSnap.data().phoneNumber,
            email: auth.currentUser.email,
            street: docSnap.data().street,
            townOrCity: docSnap.data().townOrCity,
            postcode: docSnap.data().postcode,
          });
        }
      };

      fetchUserAccountInformation().then(() => setShowSpinner(false));
    } else {
      // User is somehow not logged in
      toast.error("You need to be logged in to view this page.", {
        autoClose: 1000,
      });
      navigate("/");
    }

    // Get previous purchases for logged in user
  }, [navigate, auth.currentUser]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const updateUserAccountDetails = async (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      const user = auth.currentUser;

      const docRef = doc(db, "users", user.uid);

      setShowSpinner(true);
      await updateDoc(docRef, {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
        street: street.length > 0 ? street : "",
        townOrCity: townOrCity.length > 0 ? townOrCity : "",
        postcode: postcode.length > 0 ? postcode : "",
      });
    }
    setShowSpinner(false);
    toast.success("Your account details have been updated!", {
      autoClose: 1000,
    });
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <div className="flex justify-center mt-3 bg-white shadow-md pb-2">
          <h1 className="text-lg font-semibold tracking-wider">
            MY | <span className="font-normal">ACCOUNT</span>{" "}
            <FontAwesomeIcon icon={faUserCircle} />
          </h1>
        </div>
        {/* Main Container */}
        <div>
          <div className="mt-4 flex justify-center text-sm sm:text-base mb-4">
            <div
              onClick={() => setShowAccountDetails(!showAccountDetails)}
              className={`border-2 w-1/3 md:w-1/5 text-sm md:text-base text-center py-3 hover:cursor-pointer shadow-sm ${
                showAccountDetails === true
                  ? "animate duration-500 font-semibold bg-teal-400 shadow-sm text-white"
                  : ""
              }`}
            >
              Account Details
            </div>
            <div
              onClick={() => fetchUserPreviousOrders()}
              className={`border-2 w-1/3 md:w-1/5 text-sm md:text-base text-center py-3 hover:cursor-pointer shadow-sm ${
                showAccountDetails === false
                  ? "animate duration-500 font-semibold bg-teal-400 shadow-sm text-white"
                  : ""
              }`}
            >
              Previous Orders
            </div>
          </div>

          {showSpinner ? (
            <Spinner />
          ) : showAccountDetails ? (
            <div className="flex justify-center">
              <div className="w-11/12 md:w-1/2 xl:w-3/12">
                <form onSubmit={updateUserAccountDetails}>
                  <div className="flex flex-col py-2 mb-2 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      // placeholder={formData ? formData.name : ""}
                      value={formData ? formData.firstName : ""}
                      onChange={onChange}
                    ></input>
                  </div>
                  <div className="flex flex-col py-2 mb-2 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      // placeholder={formData ? formData.name : ""}
                      value={formData ? formData.lastName : ""}
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 w-full mb-2 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Email</label>
                    <input
                      type="email"
                      id="email"
                      // placeholder="Enter email"
                      value={formData ? formData.email : ""}
                      // defaultValue={formData ? formData.email : ""}
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 w-full mb-2 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Phone Number</label>
                    <input
                      type="text"
                      id="phoneNumber"
                      // placeholder="Enter email"
                      value={formData ? formData.phoneNumber : ""}
                      // defaultValue={formData ? formData.email : ""}
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 mb-3 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">
                      Street Address
                    </label>
                    <input
                      type="text"
                      id="street"
                      value={street ? street : ""}
                      placeholder=""
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 mb-3 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Town/City</label>
                    <input
                      type="text"
                      id="townOrCity"
                      placeholder=""
                      value={townOrCity ? townOrCity : ""}
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 mb-3 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Postcode</label>
                    <input
                      type="text"
                      id="postcode"
                      value={postcode ? postcode : ""}
                      placeholder=""
                      onChange={onChange}
                    ></input>
                  </div>

                  <div className="flex flex-col py-2 mb-3 text-xs sm:text-base">
                    <label className="text-xs sm:text-base">Country</label>
                    <input
                      type="text"
                      placeholder=""
                      disabled={true}
                      value="Australia"
                      readOnly={true}
                    ></input>
                  </div>

                  {/* Update user account details */}
                  <div className="text-sm sm:text-base text-center mb-2 py-2 border-gray-300 duration-300 hover:cursor-pointer">
                    <button className="bg-emerald-500 text-center text-white font-semibold rounded-md p-3 duration-300 hover:bg-emerald-600 hover:cursor-pointer mx-4">
                      Save Changes{" "}
                    </button>
                  </div>
                </form>

                {/* Logout */}
                <div
                  onClick={() => logoutUser()}
                  className="text-sm sm:text-base text-center py-2 border-gray-300 duration-300  hover:cursor-pointer mb-2"
                >
                  <button className="bg-orange-500 text-center text-white font-semibold rounded-md p-3 duration-300 hover:bg-orange-600 hover:cursor-pointer  mx-4">
                    Log Out{" "}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <PurchaseHistory previousOrders={previousOrders} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyAccount;
