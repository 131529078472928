import React from "react";
import useFetchCompetitions from "../hooks/useFetchCompetitions";
import Spinner from "../components/Spinner";
import ActiveCompetitionCard from "../components/ActiveCompetitionCard";
import Footer from "../components/Footer";

const Competitions = () => {
  const { isLoading, competitions } = useFetchCompetitions();

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <h2 className="text-center pb-3 my-3 text-lg bg-white shadow-md font-semibold tracking-wider">
          ACTIVE | <span className="font-normal">COMPETITIONS</span>
        </h2>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center">
          {competitions &&
            competitions.map((competition, index) => {
              return (
                <ActiveCompetitionCard
                  competition={competition}
                  isLoading={isLoading}
                  key={index}
                />
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Competitions;
