import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";
import { BeatLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";

const useFetchCompetition = () => {
  const [competition, setCompetition] = useState(null);
  const [competitionID, setCompetitionID] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfEntriesRemaining, setNumberOfEntriesRemaining] = useState(0);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchCompetition = async () => {
      const docRef = doc(db, "competitions", params.competitionID);
      const entriesDocRef = doc(
        db,
        "competitions",
        params.competitionID,
        "entriesCollection",
        "entriesDocument"
      );
      const docSnap = await getDoc(docRef);
      const entriesDocSnap = await getDoc(entriesDocRef);
      if (docSnap.exists()) {
        setCompetition(docSnap.data());
        if (entriesDocSnap.exists()) {
          setNumberOfEntriesRemaining(
            entriesDocSnap.data().numberOfEntriesRemaining
          );

          setCompetitionID(params.competitionID);
          setIsLoading(false);
        }
      }
    };
    fetchCompetition();
  }, [params.competitionID]);

  return { competition, numberOfEntriesRemaining, isLoading, competitionID };
};

export default useFetchCompetition;
