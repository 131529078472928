import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faMinusCircle,
  faPlusCircle,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

const AdminCreateNewCompetition = () => {
  const [formData, setFormData] = useState({
    competitionTitle: "",
    drawDate: "",
    numberOfEntries: 0,
    priceOfEntry: 0,
    discountedPriceOfEntry: 0,
    maxNumberOfEntries: 0,
    isActive: true,
    competitionDescription: "",
    isFeatured: false,
    hasInstantWinPrizes: false,
    instantWinPrizes: [],
    images: {},
    banner: {},
    supportingCharity: "",
  });

  const [showSpinner, setShowSpinner] = useState(false);

  const {
    competitionTitle,
    drawDate,
    numberOfEntries,
    priceOfEntry,
    discountedPriceOfEntry,
    maxNumberOfEntries,
    isActive,
    competitionDescription,
    isFeatured,
    images,
    banner,
    hasInstantWinPrizes,
    instantWinPrizes,
  } = formData;

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid === "u2vSojNGeQWu8RGluaxs9sUftMh1") {
        } else {
          //toast.error("You are not logged in as an admin user.");
          navigate("/not-found");
        }
      } else {
        navigate("/not-found");
      }
    });
  }, []);

  const generateUniqueEntryNumber = (
    existingNumbers,
    numberOfAvailableEntries
  ) => {
    let newNumber;
    let attempts = 0; // Add an attempt limiter to avoid potential infinite loops
    do {
      newNumber = Math.floor(Math.random() * numberOfAvailableEntries) + 1;
      attempts++;
    } while (
      existingNumbers.includes(newNumber) &&
      attempts < numberOfAvailableEntries
    );
    if (attempts === numberOfAvailableEntries) {
      // Handle the scenario where a unique number cannot be generated
      throw new Error("Unable to generate a unique winning entry number");
    }
    return newNumber;
  };

  // Store image in firebase
  const storeImage = async (image) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = uuidv4();

      const storageRef = ref(storage, "images/" + fileName);

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          reject(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const storeBanner = async (banner) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = uuidv4();

      const storageRef = ref(storage, "banners/" + fileName);

      const uploadTask = uploadBytesResumable(storageRef, banner);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          reject(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const storeInstantWinPrizeImage = async (image) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = uuidv4();
      const storageRef = ref(storage, "instantWinPrizes/" + fileName);

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // handle snapshot states if you wish
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const createNewCompetition = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const imageURLs = await Promise.all(
      [...images].map((image) =>
        storeImage(image).catch(() => {
          setShowSpinner(false);
          toast.error("Images failed to upload");
          return;
        })
      )
    );

    // Store banner and return bannerURL
    const bannerURL = await storeBanner(banner);

    // Upload InstantWinPrizes Images and store with title
    const instantWinPrizeDetails = await Promise.all(
      instantWinPrizes.map(async (prize) => {
        const prizeImageURL = await storeInstantWinPrizeImage(prize.image);
        return {
          title: prize.title,
          imageUrl: prizeImageURL,
          status: "AVAILABLE",
        };
      })
    );

    const formDataCopy = {
      ...formData,
      imageURLs,
      bannerURL,
      timestamp: serverTimestamp(),
    };

    // No need to add this to DB
    delete formDataCopy.images;
    delete formDataCopy.banner;

    // Now, before storing instantWinPrizesURLs, assign unique winning numbers
    const existingWinningNumbers = [];
    instantWinPrizeDetails.forEach((prize) => {
      try {
        prize.winningEntryNumber = generateUniqueEntryNumber(
          existingWinningNumbers,
          numberOfEntries
        );
        existingWinningNumbers.push(prize.winningEntryNumber);
      } catch (error) {
        // Handle the error, maybe revert the entire transaction, or alert the admin
        console.error(error);
        setShowSpinner(false);
        toast.error(error.message);
        return;
      }
    });

    const competitionRef = await addDoc(collection(db, "competitions"), {
      competitionTitle,
      drawDate: drawDate,
      numberOfEntries,
      priceOfEntry,
      discountedPriceOfEntry,
      maxNumberOfEntries,
      numberOfEntriesRemaining: numberOfEntries,
      isActive,
      competitionDescription,
      isFeatured,
      imageURLs,
      bannerURL,
      ...(instantWinPrizeDetails.length > 0 && { instantWinPrizeDetails }),
      timestamp: serverTimestamp(),
    });

    // Create array of available entry numbers
    let availableEntriesArray = [];
    for (let i = 1; i <= numberOfEntries; i++) {
      availableEntriesArray.push(i);
    }

    // Create available entries array
    await setDoc(
      doc(
        db,
        "competitions",
        competitionRef.id,
        "entriesCollection",
        "entriesDocument"
      ),
      {
        availableEntries: availableEntriesArray,
        numberOfEntriesRemaining: availableEntriesArray.length,
      }
    );

    setShowSpinner(false);
    toast.success("Competition created successfully!");
    navigate(`/`);
  };

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number" && value !== "") {
      value = parseInt(value, 10);
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const onChangeIsFeatured = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      isFeatured: !isFeatured,
    }));
  };

  const onChangeHasInstantWinPrizes = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      hasInstantWinPrizes: !hasInstantWinPrizes,
    }));
  };

  const onChangeIsActive = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      isActive: !isActive,
    }));
  };

  // const onChangeIsClubFundraiser = (e) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     isClubFundraiser: !isClubFundraiser,
  //   }));
  // };

  const onAddFiles = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      images: e.target.files,
    }));
  };

  const onAddBanner = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      banner: e.target.files[0],
    }));
  };

  const addPrize = () => {
    setFormData((prevState) => ({
      ...prevState,
      instantWinPrizes: [
        ...prevState.instantWinPrizes,
        { title: "", image: null },
      ],
    }));
  };

  const removePrize = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      instantWinPrizes: prevState.instantWinPrizes.filter(
        (_prize, index) => index !== indexToRemove
      ),
    }));
  };

  const updatePrize = (index, title, image) => {
    setFormData((prevState) => {
      let updatedPrizes = [...prevState.instantWinPrizes];
      updatedPrizes[index] = { title, image };
      return {
        ...prevState,
        instantWinPrizes: updatedPrizes,
      };
    });
  };

  if (showSpinner) return <Spinner />;

  return (
    <>
      {/* <NavBar /> */}
      <h2 className="text-center mt-4 text-lg">
        {/* Create New Competition <FontAwesomeIcon icon={faUserAlt} /> */}
      </h2>
      <div className="flex justify-center">
        <div className="border-b-2 w-1/3 my-2"></div>
      </div>
      <div className="flex justify-center">
        <form className="w-1/3" onSubmit={createNewCompetition}>
          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">Competition Title</label>
            <input
              className=""
              type="text"
              id="competitionTitle"
              value={competitionTitle}
              onChange={onChange}
              placeholder=""
              required
            ></input>
          </div>
          {/* <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1" htmlFor="charity">
              Select a Charity
            </label>
            <select
              name="charity"
              id="charity"
              className="border-2 py-2 rounded px-1"
            >
              <option value="SCHF">
                Sydney Children's Hospital Foundation
              </option>
            </select>
          </div> */}
          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">Draw Date</label>
            <input
              className=""
              type="date"
              id="drawDate"
              value={drawDate}
              onChange={onChange}
              required
            ></input>
            {/* <input
              className=""
              type="text"
              id="drawDate"
              value={drawDate}
              onChange={onChange}
              placeholder="E.g. 9th April 3PM (AEST)"
              required
            ></input> */}
          </div>
          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">Number of Entries</label>
            <input
              className=""
              type="number"
              id="numberOfEntries"
              value={numberOfEntries}
              onChange={onChange}
              required
            ></input>
          </div>
          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">Price of Entry (AUD)</label>
            <input
              className=""
              type="number"
              id="priceOfEntry"
              value={priceOfEntry}
              onChange={onChange}
            ></input>
          </div>
          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">
              Discounted Entry Price (Optional)
            </label>
            <input
              className=""
              type="number"
              id="discountedPriceOfEntry"
              value={discountedPriceOfEntry}
              onChange={onChange}
              required
            ></input>
          </div>
          <div className="flex flex-col py-2 mb-2">
            <label className="font-bold mb-1">
              Max Number of Entries (Per Person)
            </label>
            <input
              className=""
              type="number"
              id="maxNumberOfEntries"
              value={maxNumberOfEntries}
              onChange={onChange}
              required
            ></input>
          </div>
          <div className="flex py-2 w-full mb-2">
            <label className="font-bold mb-1">Competition Is Active:</label>
            <input
              className="w-20"
              type="checkbox"
              id="isActive"
              value={isActive}
              onChange={onChangeIsActive}
              //checked={isActive}
              defaultChecked={isActive}
              placeholder=""
            ></input>
          </div>
          <div className="flex flex-col w-full py-2 mb-2">
            <label className="font-bold mb-1">Competition Description</label>

            <textarea
              placeholder="Enter competition details"
              value={competitionDescription}
              id="competitionDescription"
              onChange={onChange}
              className="border-2 p-2"
              required
            ></textarea>
          </div>

          <div className="flex py-2 w-72 mb-2">
            <label className="font-bold mb-1">Is Featured? (Banner):</label>
            <input
              className="w-20"
              type="checkbox"
              id="isFeatured"
              value={isFeatured}
              onChange={onChangeIsFeatured}
              placeholder=""
              defaultChecked={isFeatured}
            ></input>
          </div>

          {/* <div className="flex py-2 w-72 mb-2">
            <label className="font-bold mb-1">Is Club Fundraiser?</label>
            <input
              className="w-20"
              type="checkbox"
              id="isClubFundraiser"
              value={isClubFundraiser}
              onChange={onChangeIsClubFundraiser}
              placeholder=""
              defaultChecked={isClubFundraiser}
            ></input>
          </div> */}

          <div className="flex flex-col py-2 w-full mb-2">
            <label className="font-bold mb-1">Upload competition images:</label>
            <input
              className=""
              type="file"
              id="images"
              multiple
              required
              onChange={onAddFiles}
            ></input>
          </div>
          {isFeatured ? (
            <div className="flex flex-col py-2 w-full mb-2">
              <label className="font-bold mb-1">Upload banner:</label>
              <input
                className=""
                type="file"
                id="banner"
                onChange={onAddBanner}
              ></input>
            </div>
          ) : (
            <></>
          )}

          <div className="flex py-2 w-72 mb-2">
            <label className="font-bold mb-1">Has instant win prizes?</label>
            <input
              className="w-20"
              type="checkbox"
              id="isFeatured"
              value={hasInstantWinPrizes}
              onChange={onChangeHasInstantWinPrizes}
              placeholder=""
              defaultChecked={hasInstantWinPrizes}
            ></input>
          </div>

          {hasInstantWinPrizes ? (
            <div>
              <p className="font-semibold border-top pt-2 text-center">
                Instant Win Prizes
              </p>
              {instantWinPrizes.map((prize, index) => (
                <div key={index} className="my-3 border-bottom pb-3">
                  <div className="flex flex-col w-full mb-2">
                    <label className="mr-3">Prize Title</label>
                    <input
                      type="text"
                      value={prize.title}
                      onChange={(e) =>
                        updatePrize(index, e.target.value, prize.image)
                      }
                      required
                    ></input>
                  </div>
                  <div>
                    <label>Prize Image</label>
                    <input
                      type="file"
                      onChange={(e) =>
                        updatePrize(index, prize.title, e.target.files[0])
                      }
                      required
                    ></input>
                  </div>

                  <button
                    className="hover:font-semibold mt-3"
                    type="button"
                    onClick={() => removePrize(index)}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} /> Remove prize
                  </button>
                </div>
              ))}

              <button
                className="hover:font-semibold"
                type="button"
                onClick={addPrize}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add instant win prize
              </button>
            </div>
          ) : null}

          <div className="text-center rounded-md py-2 mt-4 border-2 border-gray-300 duration-300 hover:bg-gray-300 hover:border-gray-400 hover:cursor-pointer mb-5">
            <button type="submit" className="text-lg">
              Create Competition{" "}
              <FontAwesomeIcon icon={faPlusCircle} color="grey" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminCreateNewCompetition;
