import React, { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { BeatLoader } from "react-spinners";
import { getDoc, doc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useShoppingCart } from "../context/useShoppingCart";
import { toast } from "react-toastify";

const CartCompetitionItem = ({ competitionID, numberOfTickets }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [competition, setCompetition] = useState({});
  const { removeFromCart, updateTicketCount, getNumberOfTicketsInCart } =
    useShoppingCart();

  // Call this function when the "+" button is clicked
  const handleIncrement = (event) => {
    event.preventDefault();
    updateTicketCount(competitionID, 1); // Increment by 1
  };

  // Call this function when the "-" button is clicked
  const handleDecrement = (event) => {
    event.preventDefault();
    updateTicketCount(competitionID, -1); // Decrement by 1
  };

  useEffect(() => {
    const fetchCompetition = async () => {
      const docRef = doc(db, "competitions", competitionID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCompetition(docSnap.data());
        setIsLoading(false);
      }
    };
    fetchCompetition();
  }, [competitionID]);

  return (
    <>
      {isLoading ? (
        <BeatLoader />
      ) : (
        <div className="flex flex-row gap-1 mb-3 p-2 border-b-2 shadow shadow-sm">
          <div className="basis-5/12 sm:basis-3/12 mr-3">
            <img
              src={competition.imageURLs[0]}
              alt={competition.competitionTitle}
              className="w-full sm:w-48 rounded-md"
            />
          </div>
          <div className="basis-7/12 sm:basis-9/12">
            <div className="flex justify-between mb-3">
              <div className="text-xs sm:text-base font-semibold border-b-2 border-gray-300/100 py-1 w-10/12">
                {competition.competitionTitle}
              </div>
              <FontAwesomeIcon
                icon={faX}
                className="cursor-pointer"
                onClick={() => removeFromCart(competitionID)}
              />
            </div>
            <div className="my-1 text-sm sm:text-base">
              <span className="font-semibold">Price:</span> $
              {competition.priceOfTicket}
            </div>
            <div className="flex justify-between">
              <div className="my-1 text-sm sm:text-base">
                <span className="font-semibold">Tickets: </span>

                <div className="flex w-fit flex-row items-center justify-center rounded-sm border border-black-500">
                  <button className="mx-2" onClick={handleDecrement}>
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <p className="px-4 mt-3 font-semibold text-sm ">
                    {getNumberOfTicketsInCart(competitionID)}
                  </p>
                  <button className="mx-2" onClick={handleIncrement}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              <div className="mt-1 text-sm sm:text-base">
                <span className="font-semibold">Total: </span> $
                {getNumberOfTicketsInCart(competitionID) *
                  competition.priceOfTicket}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartCompetitionItem;
