import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PreviousWinners = () => {
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <div className="bg-gradient-to-r bg-white shadow-md">
          <p className="text-center text-lg font-semibold pt-3 pb-3 sm:pb-0 tracking-wider">
            PREVIOUS | <span className="font-normal">WINNERS</span>
          </p>
        </div>
        <h3 className="text-lg sm:text-xl text-center my-5">
          No Winners Just Yet!
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default PreviousWinners;
