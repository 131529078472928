import React from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const PackageCard = ({
  type,
  priceOfEntry,
  livingInAustralia,
  handleAddToCart,
  addingToCart,
}) => {
  let shadowColor,
    bgColor,
    textColor,
    borderColor,
    buttonColor,
    buttonHoverColor;

  switch (type) {
    case "bronze":
      shadowColor = "shadow-[#cd7f32]/50";
      bgColor = "bg-[#cd7f32]/5";
      textColor = "text-[#cd7f32]";
      borderColor = "border-[#cd7f32]";
      buttonColor = "bg-[#cd7f32]";
      buttonHoverColor = "hover:bg-[#b36b28]";
      break;
    case "silver":
      shadowColor = "shadow-[#c0c0c0]/50";
      bgColor = "bg-[#c0c0c0]/5";
      textColor = "text-[#c0c0c0]";
      borderColor = "border-[#c0c0c0]";
      buttonColor = "bg-[#c0c0c0]";
      buttonHoverColor = "hover:bg-[#a9a9a9]";
      break;
    case "gold":
      shadowColor = "shadow-[#ffd700]/50";
      bgColor = "bg-[#ffd700]/5";
      textColor = "text-[#ffd700]";
      borderColor = "border-[#ffd700]";
      buttonColor = "bg-[#ffd700]";
      buttonHoverColor = "hover:bg-[#e6c200]";
      break;
    default:
      shadowColor = "shadow-gray-500";
      bgColor = "bg-white";
      textColor = "text-gray-700";
      borderColor = "border-gray-300";
      buttonColor = "bg-gray-300";
      buttonHoverColor = "hover:bg-gray-400";
  }

  const createUserInPropello = async () => {
    if (!livingInAustralia) {
      toast.error(
        "You must select 'I am living in Australia' to add to cart.",
        { autoClose: 4000 }
      );
    } else {
      try {
        const response = await fetch(
          "https://handlepropelloactivation-createorreactivateuserin-ybv5mc22oq-ts.a.run.app"
        );
      } catch (error) {}
    }
  };

  return (
    <div
      className={`max-w-sm  rounded overflow-hidden shadow-md ${shadowColor} ${bgColor} p-6 border-2 ${borderColor} duration-300`}
    >
      <div className={`font-bold text-xl mb-2 text-center ${textColor}`}>
        {type === "bronze"
          ? "Bronze Package"
          : type === "silver"
          ? "Silver Package"
          : "Gold Package"}
      </div>
      <p className={`text-center tracking-wide font-semibold text-xl`}>
        $
        {type === "bronze"
          ? priceOfEntry.toFixed(2)
          : type === "silver"
          ? (priceOfEntry * 2.5).toFixed(2)
          : (priceOfEntry * 4).toFixed(2)}
      </p>
      <p className="text-sm text-gray-700 font-semibold text-center">
        30 days access to Lift Rewards
      </p>
      <p className="text-base text-gray-700 font-bold text-center">
        {type === "bronze"
          ? "1 entry"
          : type === "silver"
          ? "3 entries"
          : "5 entries"}
      </p>
      <div className="flex justify-center mt-4">
        <button
          className={
            livingInAustralia
              ? `text-center py-2 rounded-md w-full shadow-md ${buttonColor} ${buttonHoverColor} text-white duration-200 hover:font-bold`
              : `text-center py-2 rounded-md w-full shadow-md bg-gray-400 text-white duration-200`
          }
          onClick={() => handleAddToCart(type)}
        >
          {addingToCart ? <BeatLoader color="white" /> : "Add to cart"}
        </button>
      </div>
    </div>
  );
};

const Packages = ({
  competition,
  livingInAustralia,
  handleAddToCart,
  addingToCart,
}) => {
  const { priceOfEntry } = competition;
  return (
    <div className="w-full flex flex-col md:flex-row justify-center items-center align-center gap-3 p-4">
      <PackageCard
        type="bronze"
        priceOfEntry={priceOfEntry}
        livingInAustralia={livingInAustralia}
        handleAddToCart={handleAddToCart}
        addingToCart={addingToCart}
      />
      <PackageCard
        type="silver"
        priceOfEntry={priceOfEntry}
        livingInAustralia={livingInAustralia}
        handleAddToCart={handleAddToCart}
        addingToCart={addingToCart}
      />
      <PackageCard
        type="gold"
        priceOfEntry={priceOfEntry}
        livingInAustralia={livingInAustralia}
        handleAddToCart={handleAddToCart}
        addingToCart={addingToCart}
      />
    </div>
  );
};

export default Packages;
