import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { app } from "../firebase.config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Footer from "../components/Footer";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const [showSpinner, setShowSpinner] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth(app);

  const windowWidth = window.screen.width;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const signUserIn = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        setShowSpinner(false);
        toast.success(`Welcome ${userCredential.user.displayName}!`, {
          autoClose: 1000,
        });
        navigate("/");
      }
    } catch (error) {
      //toast.error("Incorrect email or password.");
      setShowSpinner(false);
    }
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          WELCOME | <span className="font-normal">BACK</span>
        </p>
        {/* Logon Card */}
        <div className="flex justify-center mb-5">
          <div className="border-2 rounded-2xl border-gray-300/80 py-4 px-3 shadow-inner shadow-md bg-gray-50">
            {/* Card icon */}
            <div className="text-center">
              <FontAwesomeIcon
                icon={faSignInAlt}
                color="black"
                size={windowWidth < 400 ? "2x" : "3x"}
                className="mb-2"
              />

              {/* Heading */}
              <p className="mt-2 text-base sm:text-lg font-semibold">Sign In</p>
            </div>

            {/* Logon Fields */}
            <div className="flex">
              <form onSubmit={signUserIn}>
                <div className="flex flex-col py-2 w-72 mb-2">
                  <label className="text-sm font-semibold mb-1">Email</label>
                  <input
                    className="border p-2 text-sm sm:text-base"
                    type="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Enter email"
                  ></input>
                </div>
                <div className="flex flex-col py-2 mb-3">
                  <label className="text-sm font-semibold mb-1">Password</label>
                  <input
                    className="border p-2 text-sm sm:text-base"
                    type="password"
                    id="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={onChange}
                  ></input>
                </div>
                <Link to="/forgot-password" className="text-sm">
                  Forgot Password
                </Link>

                {showSpinner ? (
                  <div className="flex mt-5 mb-4 justify-center">
                    <BeatLoader color="#36D7B7" />
                  </div>
                ) : (
                  // Sign in button
                  <div className="text-center rounded-md py-2 mt-4 border-2 border-gray-300 duration-300 hover:bg-gray-300 hover:cursor-pointer mb-4 sm:mb-5">
                    <button className="text-base">
                      Sign In{" "}
                      <FontAwesomeIcon icon={faArrowCircleRight} color="grey" />
                    </button>
                  </div>
                )}
                {/* Sign Up Instead */}

                {/* <OAuth /> */}

                <div
                  onClick={() => navigate("/sign-up")}
                  className="text-center py-2 mt-4 border-gray-300 duration-300 hover:font-semibold hover:cursor-pointer sm:mb-3"
                >
                  <button className="text-base text-teal-400">
                    Sign Up Instead{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default SignIn;
