import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          TERMS & | <span className="font-normal">CONDITIONS</span>
        </p>
        <div className="flex flex-col mx-3 lg:px-48 text-sm sm:text-base">
          <p className="font-semibold">Lift Competitions Pty Ltd</p>
          <p className="font-semibold">Competition Rules:</p>
          <p className="font-semibold">1. Entry</p>
          <p className="font-semibold">1.1 Eligable Participants</p>
          <p>
            Competition participants must be 18 years or older to enter. Persons
            ineligable for entry into the competition are:
          </p>
          <ul>
            <li>
              1. Any executive, member or employee associated with Lift
              Competitions.
            </li>
            <li>
              2. Other party members involved with competition (including
              employees and agents).
            </li>
            <li>
              3. Any extended family members of those included in (1) and (2).
            </li>
            <li>4. Anyone who is not 18 years old.</li>
          </ul>

          <p className="font-semibold">1.2 Eligable Entries</p>
          <p>
            The competition is open to Participants who submit all the required
            information under clause 4, between the opening and closing dates
            and times and have complied with these rules and all other
            reasonable requirements of Lift Competitions. Eligible entrants will
            be deemed to have accepted these rules.
          </p>

          <p className="font-semibold">2. Tickets</p>
          <p className="font-semibold">2.1 Issue</p>
          <p>
            Your ticket(s) will be issued once all entry details and full
            payment have been received.
          </p>

          <p className="font-semibold">2.2 Amending Ticket Details</p>
          <p>
            Tickets may be amended or updated if details submitted are
            incorrect, subject to the draw being still open as determined by the
            closing time.
          </p>

          <p className="font-semibold">2.3 Numbering</p>
          <p>
            Ticket numbers are generated randomly between 1 and the maximum
            number of tickets for each competition.
          </p>

          <p className="font-semibold">3. Prizes</p>
          <p className="font-semibold">3.1 Prizes Displayed on Website</p>
          <p>
            Each individual competition showcases it's specific prize within the
            competition webpage details.
          </p>
          <p className="font-semibold">3.2 Details of Prizes</p>
          <p>
            The details describing the prize on the website are accurate to Lift
            Competitions’ knowledge and belief. However, the prizes offered are
            subject to availability and Lift Competitions reserves the right to
            a prize with one of equal or greater value. If in the event a prize
            is no longer available, Lift Competitions will update its website
            and notify all entrants as soon as possible. Prizes may also be
            supplied by a third party. The details of a third-party supplier
            will be displayed on our website.
          </p>
          <p className="font-semibold">3.3 Total Prize Pool</p>
          <p>
            The total market value of the prizes is displayed on the Lift
            Competitions website. Please check the website for more details.
            Lift Competitions accepts no responsibility or liability for a
            change in the prize value.
          </p>
          <p className="font-semibold">3.4 Cash Exchanges </p>
          <p>
            Prizes are not exchangable for cash or negotiable, unless
            specifically described within the competition details.
          </p>
          <p className="font-semibold">4. The Competition </p>
          <p className="font-semibold">4.1 Competition Dates & Times</p>
          <p>
            The competition opening and closing dates and times are listed on
            the Lift Competitions website. Please refer to each competition for
            details.
          </p>
          <p className="font-semibold">4.2 Minimum Number of Entries</p>
          <p>
            The minimum number of entries for each competition is number stated
            on our website. If the minimum number of entries is not reached,
            Lift Competitions reserves the right the cancel the competition and
            refund any tickets purchased. .
          </p>
          <p className="font-semibold">4.3 Maximum Number of Entries</p>
          <p>
            The maximum number of entries for each competition is be stated on
            the competition website. Entries made after the maximum number of
            entries are not valid.
          </p>
          <p className="font-semibold">4.4 Change of Dates</p>
          <p>
            Lift Competitions reserves the right to alter the opening and
            closing dates and times of the competition. If the opening and
            closing dates are changed, the competition details on Lift
            Competitions website will be updated.
          </p>
          <p className="font-semibold">4.5 Entry</p>
          <p>
            Entrants must enter all the required information listed on the
            competition website, including: Full name; Address; Telephone
            number; Date of birth; and Any other information required by Lift
            Competitions.
          </p>
          <p className="font-semibold">4.6 Entry Mistakes</p>
          <p>
            Lift Competitions accepts no responsibility for any late, lost,
            incomplete, misdirected, incorrectly submitted, delayed or illegible
            entries, claims or correspondence howsoever caused. Lift
            Competitions is not liable for any consequences resulting from user
            or technological error (including and without limitation to costs
            incurred).
          </p>
          <p className="font-semibold">4.7 Void Entries</p>
          <p>
            An entry is void if the entrant engages in: any form of fraud or
            fraudulent misrepresentation; automatization; hacking or
            interference with the proper functioning of the website; or
            amending, or unauthorised use of, any of the code that constitutes
            Lift Competitions website.
          </p>
          <p className="font-semibold">4.8 Draw Date</p>
          <p>
            The draw will take plLift at the plLift, date and time displayed on
            the competition website page.
          </p>
          <p className="font-semibold">4.9 Entry Mistakes</p>
          <p>
            The draw of all valid entries will be made randomly using a random
            ticket generator at the address listed on the competition website
            page as soon as practicable following the close of the raffle.
          </p>
          <p className="font-semibold">4.10 Notification</p>
          <p>
            Winners will be notified by telephone, mail, or email within 2 days
            of the draw.
          </p>
          <p className="font-semibold">4.11 Claiming Prize and Delivery</p>
          <p>
            All reasonable efforts will be made to contact the raffle winner(s)
            to organise delivery. If the prize is not claimed within 3 months,
            Lift Competitions will seek permission from the regulator for the
            prize to be sold.
          </p>

          <p className="font-semibold">5. Privacy</p>
          <p className="font-semibold">5.1 Privacy Principles</p>
          <p>
            Lift Competitions is bound by the Australian Privacy Principles
            contained in the Privacy Act 1988 (Cth) which determine how personal
            information can be collected, used disclosed, stored and destroyed,
            as well as how a person may access or complain about the use or
            storage of their personal information.
          </p>
          <p className="font-semibold">5.2. Collection, Use & Disclosure</p>
          <p>
            Participants accept that Lift Competitions collects personal
            information and data supplied by participants in order to conduct
            the raffle in accordance with these rules, such as: Your contact
            details (full name, street address, billing address, postal address,
            email address and telephone number); Your date of birth and age;
            Occupation; and Proof of identity, including driver’s licence and
            passport. Participants acknowledge and agree that their information
            may be disclosed to third parties if required by law. You agree that
            your name, raffle number, town may be used if you win a prize in a
            competition and also agree to do any reasonable publicity required.
            You are entitled to request what information we hold about you. We
            may, with your consent, communicate promotional offers and marketing
            to you. Participants acknowledge and agree that their information
            may be disclosed to third party, including: If Lift Competitions is
            under a legal obligation; If Lift Competitions needs to enforce
            these rules; If Lift Competitions needs to disclose it protect its
            rights, property or safety of itself and others.Lift Competitions
            will take all reasonable steps to protect your personal information
            from misuse, loss, unauthorised access, or modification.
          </p>
          <p className="font-semibold">
            5.3 Winners Requirement to Provide Further Information
          </p>
          <p>
            Prize winners may also be required to disclose further information
            for identification and confirmation of eligibility purposes. If
            further information is not supplied the draw may be voided and
            another winner selected. You agree that your name, raffle number,
            town may be used if you win a prize in a competition and also agree
            to do any reasonable publicity required.
          </p>

          <p className="font-semibold">6. Direct Marketing</p>
          <p className="font-semibold">6.1 Entry Into a Raffle</p>
          <p>
            By entering into a raffle draw, you consent to Lift Competitions
            using your personal information to contact you with information
            about our services / future raffles which Lift Competitions consider
            may be of interest to you, as well as other information which may
            reasonable interest you. You may opt out of this communication at
            any time.
          </p>

          <p className="font-semibold">7. Liability & Disclaimer</p>
          <p className="font-semibold">7.1 Exclusion of Liability</p>
          <p>
            Lift Competitions, any associated companies, and employees are not
            be liable for any loss (including indirect, special or consequential
            loss) expense, damage, personal injury or death which is suffered or
            sustained in connection with this competition or accepting or using
            any prize, except for negligence and any liability which cannot be
            excluded by law (in which case that liability is limited to the
            minimum allowable by law).
          </p>
          <p className="font-semibold">7.2 Delivery</p>
          <p>
            Without limiting the previous paragraph, Lift Competitions, any
            associated companies and employees are not liable for any damage in
            transit to any prize.
          </p>
          <p className="font-semibold">7.3 Intellectual Property</p>
          <p>
            Without limiting the previous paragraph, Lift Competitions, any
            associated companies and employees are not liable for any damage in
            transit to any prize.
          </p>
          <p className="font-semibold">7.4 Acknowledgement</p>
          <p>
            By entering into any draw, you acknowledge that these rules have
            provided reasonable warning of eligibility, prize types, drawing
            processes. You also acknowledge that you have had a reasonable
            opportunity to seek legal advice on these rules any competitions.
          </p>
          <p className="font-semibold">7.5 Australian Consumer Law</p>
          <p>
            Our goods and services come with guarantees that cannot be excluded
            under the Australian Consumer Law. If a good has a major problem
            (unsafe, significantly different from the sample or description) you
            can choose a refund, exchange or compensation. Lift Competitions is
            not responsible for a change of mind, if the prize was misused in a
            way that caused the issue, or knew of or were made aware of the
            faults before you bought the product.
          </p>

          <p className="font-semibold">8. Legislation & Licensing</p>
          <p className="font-semibold">8.1 Legislation</p>
          <p>
            Raffle conducted in accordance with the provisions of the Community
            Gaming Act 2018 (NSW) and Community Gaming Regulation 2020 (NSW),
            the Charitable Fundraising Act 1999 (NSW), and associated
            regulations.
          </p>
          <p className="font-semibold">8.2 License</p>
          <p className="font-semibold">
            Charitable fundraising number: CFN/26601
          </p>
          <p className="font-semibold">8.3 Jurisdiction</p>
          <p>
            Lift Competitions and participants irrevocably submit to the
            non-exclusive jurisdiction of courts of NSW and agree to hear,
            settle and/or determine any dispute, controversy or claim (including
            any non-contractual dispute, controversy or claim) arising out of or
            in connection with these rules.
          </p>

          <p className="font-semibold">9. Miscellaneous</p>
          <p className="font-semibold">9.1 Disclosure</p>
          <p>
            Lift Competitions is not receiving a commission or fee for their
            organisation of the fundraising but will liable to pay a management
            fee for the organisation of the fundraising.
          </p>
          <p className="font-semibold">9.2 Amendments</p>
          <p>
            Lift Competitions reserves the right to amend and update these
            rules.
          </p>
          <p className="font-semibold">9.3 Breach of Rules</p>
          <p>
            Lift Competitions reserves the right to exclude any participant from
            any competition.
          </p>
          <p className="font-semibold">10. Contact Information</p>
          <p>Lift Competitions Pty Ltd</p>
          <p>Email: info@liftcompetitions.com.au</p>
          <p>Website: www.liftcompetitions.com.au</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
