import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const EntryListCard = ({ entryListSheetURL, competitionTitle, drawDate }) => {
  return (
    <>
      <div className="flex flex-col bg-gray-200 shadow-md p-3 m-1">
        {/* <span className="font-semibold">{competitionTitle}</span> */}
        <span className="font-semibold">{competitionTitle}</span>
        <span className="pt-1">Draw Date: {drawDate}</span>
        <a
          href={entryListSheetURL}
          className="pt-3 text-black no-underline hover:font-semibold"
          target="_blank"
          rel="noreferrer"
        >
          View Entries <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>
    </>
  );
};

export default EntryListCard;
