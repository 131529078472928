import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  orderBy,
  doc,
  limit,
  startAfter,
} from "firebase/firestore";
import { db, app } from "../firebase.config";

const useFetchAllCompetitions = () => {
  const [competitions, setCompetitions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAllCompetitions = async () => {
    try {
      // Get reference
      const competitionsRef = collection(db, "competitions");

      // Create query
      const q = query(competitionsRef, orderBy("timestamp", "desc"));

      // Execute query
      const querySnap = await getDocs(q);

      const competitionsArray = [];

      querySnap.forEach((doc) => {
        competitionsArray.push({
          data: doc.data(),
          id: doc.id,
        });
      });

      // Get numberOfTicketsRemaining from ticketsDocument
      for (let i = 0; i < competitionsArray.length; i++) {
        const compRef = doc(
          db,
          "competitions",
          competitionsArray[i].id,
          "ticketsCollection",
          "ticketsDocument"
        );

        // Add numberOfTicketsRemaining to the competitionsArray
        const compSnap = await getDoc(compRef);
        if (compSnap.exists()) {
          competitionsArray[i] = {
            ...competitionsArray[i],
            numberOfTicketsRemaining: compSnap.data().numberOfTicketsRemaining,
          };
        }
      }

      setCompetitions(competitionsArray);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCompetitions();
  }, []);

  return { competitions, isLoading };
};

export default useFetchAllCompetitions;
