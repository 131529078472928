import React from "react";
import instantWinPrizeTicket from "../assets/instantWinTicket.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";

const InstantWinPrizeCard = ({
  winningTicketNumber,
  title,
  imageUrl,
  status,
  winner,
}) => {
  return (
    <div className="flex bg-white rounded-sm shadow-sm w-full justify-between items-center p-2">
      <div className="flex items-center gap-2">
        <img
          src={imageUrl}
          alt={title}
          className="w-14 sm:w-24 min-h-[50px] max-h-[70px]"
        />
        <p className="text-xs sm:text-sm mt-3 font-semibold mr-1 border-l-2 pl-2">
          {title}
        </p>
      </div>

      {status === "AVAILABLE" ? (
        <div className="flex flex-col items-center bg-teal-500 text-white font-semibold rounded-sm shadow-md px-2 min-w-[110px] sm:min-w-[130px] max-w-[150px]">
          <p className="text-xs sm:text-sm mt-3">{status}</p>
        </div>
      ) : (
        <div className="flex flex-col align-items-center bg-red-500 text-white font-semibold rounded-sm shadow-md px-3 min-w-[110px] sm:min-w-[130px] max-w-[150px]">
          <span className="text-xs sm:text-sm">{status}</span>
          <span className="text-xs sm:text-sm">{winner}</span>
          <span className="text-xs sm:text-sm">
            <FontAwesomeIcon icon={faTicket} /> {winningTicketNumber}
          </span>
        </div>
      )}
    </div>
  );
};

export default InstantWinPrizeCard;
