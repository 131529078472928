import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";
import Competitions from "./pages/Competitions";
import Cart from "./pages/Cart";
import Competition from "./pages/Competition";
//import FAQ from "./pages/FAQ";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import PreviousWinners from "./pages/PreviousWinners";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import TermsAndConditions from "./pages/TermsAndConditions";
import AboutUs from "./pages/AboutUs";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShoppingCartProvider } from "./context/useShoppingCart";
import OrderConfirmation from "./pages/OrderConfirmation";
import Charity from "./pages/Charity";
import Clubs from "./pages/Clubs";
//import EntryListCard from "./pages/EntryListCard";
import EntryLists from "./pages/EntryLists";
import NotFound from "./pages/NotFound";
import ContactUs from "./pages/ContactUs";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import NavBar from "./components/NavBar";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
        integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
        crossorigin="anonymous"
      />
      <ShoppingCartProvider>
        <Router>
          <ScrollToTop />
          <NavBar />
          {/* <AnimatedRoutes /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/lift-admin" element={<Admin />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/competitions" element={<Competitions />} />
            <Route path="/cart" element={<Cart />} />
            <Route
              path="/competitions/:competitionID"
              element={<Competition />}
            />
            {/* <Route path="/faq" element={<FAQ />} /> */}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/my-account" element={<PrivateRoute />}>
              <Route path="/my-account" element={<MyAccount />} />
            </Route>
            <Route path="/order-confirmation" element={<OrderConfirmation />} />
            {/* <Route path="/purchase-order/:purchaseID" element={<PrivateRoute />}>
              <Route path="/purchase-order/:purchaseID" element={<MyAccount />} />
            </Route> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/charity" element={<Charity />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/winners" element={<PreviousWinners />} />
            <Route path="/entry-lists" element={<EntryLists />} />
            {/* <Route
              path="/entry-list/:competitionID"
              element={<EntryListCard />}
            /> */}
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ShoppingCartProvider>
      <ToastContainer />
    </>
  );
};

export default App;
