import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import useFetchAllCompetitions from "../hooks/useFetchAllCompetitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AdminViewIndividualCompetition from "./AdminViewIndividualCompetition";
import Spinner from "./Spinner";

const AdminViewCompetitions = () => {
  const { competitions, isLoading } = useFetchAllCompetitions();
  const [view, setView] = useState("list"); // 'list' or 'individual'
  const [selectedCompetition, setSelectedCompetition] = useState(null);

  const handleViewDetails = (competition) => {
    setSelectedCompetition(competition);
    setView("individual");
  };

  if (isLoading) {
    return (
      <div className="flex justify-center mt-5">
        {/* <BeatLoader size={20} color="teal" /> */}
        <Spinner />
      </div>
    );
  }

  if (view === "individual" && selectedCompetition) {
    return <AdminViewIndividualCompetition competition={selectedCompetition} />;
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center align-center mt-5">
          <BeatLoader color="blue" />
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-200 mt-3">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              >
                ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              >
                Title
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              >
                Active
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              >
                Draw Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              >
                Navigate
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {competitions?.map((competition, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {competition.id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {competition.data.competitionTitle}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {competition.data.isActive.toString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {competition.data.drawDate}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleViewDetails(competition)}
                    className="text-teal-500 hover:text-teal-700"
                  >
                    <FontAwesomeIcon icon={faArrowRight} size="xl" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminViewCompetitions;
