import React, { useState } from "react";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const faqs = [
    {
      question: "How do I enter a competition?",
      answer:
        "Entering a competition is as simple as selecting one of our active competitions and purchasing a selected number of tickets. Our competitions showcase the prize details and the total number of tickets for that competition.",
    },
    {
      question: "How many tickets are for sale in each competition?",
      answer:
        "Each competition will specify the total number of tickets available within that competition.",
    },
    {
      question: "Is my purchase secure?",
      answer:
        "Every purchase is processed and secured with Square (https://squareup.com/), one of the most popular, secure payment processing platforms used across the globe. Your account/card details are never processed or stored within our system.",
    },
    {
      question: "Where can I view my purchased tickets?",
      answer:
        "When you purchase a competition ticket, the email you specified will be sent your allocated ticket numbers. You will also be redirected to a payment confirmation page which will showcase your allocated tickets. If you create an account with us, you can view your purchased tickets from 'My Account'.",
    },
    {
      question: "What happens if the competition doesn't sell out?",
      answer:
        "Lift Competitions reserve the right to increase the draw date up to 3 times if the competition does not sell out. Lift Competitions also reserve the right to issue refunds to all competition patrons if the competition ticket sales do not exceed 80% of the available tickets.",
    },
    {
      question: "Who can enter the competitions?",
      answer:
        "You must be over 18 years old and live in Australia to enter any of the competitions. If you enter the competition and do not fit this criteria, Lift Competitions Pty Ltd reserve the right to prohibit allocating the prize if you are to win.",
    },

    {
      question: "How is the winner of each competition chosen?",
      answer:
        "The winner of each competition is chosen using a random number generator. Each competition draw will be held live on our Facebook page once the required number of competition tickets has been sold.",
    },
    {
      question:
        "What happens if I win a competition prize, how will I find out?",
      answer:
        "Lift Competitions Pty Ltd will attempt to reach out to you directly to the email/phone number listed in the billing details. If a winner has not responded to the email/phone call after 30 days, Lift Competitions Pty Ltd reserve the right to reclaim or reallocate the prize.",
    },
    {
      question: "Is there an age limit for each competition?",
      answer:
        "Yes, you must be at least 18 years old to enter each competition.",
    },
    {
      question: "How can I check the entrant list?",
      answer:
        "Each entrant list will be published in from the 'Entry Lists' page in our site. This page is available in the footer of the website.",
    },
  ];

  const toggleFAQ = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div>
      <div className="mb-4 sm:mb-0 bg-white shadow-md mx-auto">
        <h1
          className={
            window.innerWidth < 800
              ? "text-lg py-3 font-semibold text-gray-900 text-center"
              : "text-lg py-3 font-semibold text-gray-900 text-center tracking-wider"
          }
        >
          FREQUENTLY | <span className="font-normal">ASKED QUESTIONS</span>
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-11/12 md:w-6/12 lg:w-8/12 xl:w-6/12">
          <div className="faq">
            {faqs.map((faq, i) => (
              <div key={i} onClick={() => toggleFAQ(i)} className="faq-item">
                <div className="faq-question">
                  {faq.question}
                  <span>{selectedQuestion === i ? "-" : "+"}</span>
                </div>
                <div
                  className={`faq-answer ${
                    selectedQuestion === i ? "open" : ""
                  }`}
                >
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
