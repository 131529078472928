import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "../css/NavBar.css";
import { app } from "../firebase.config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useShoppingCart } from "../context/useShoppingCart";
//import icon from "../assets/liftlogo1.png";
import icon from "../assets/LiftLogoWhite.png";

function NavBar({ cartData, itemsInCart }) {
  const [isOpen, setIsOpen] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth(app);
  const user = auth.currentUser;

  const screenWidth = window.innerWidth;

  const { cartItems } = useShoppingCart();

  // Check if user is signed in
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUserIsLoggedIn(true);
        // ...
      } else {
        // User is signed out
        // ...
        setUserIsLoggedIn(false);
      }
    });
  });

  const ref = useRef();
  return (
    <div>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto py-1 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between md:justify-center h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0" onClick={() => navigate("/")}>
                {screenWidth < 1050 && screenWidth > 765 ? (
                  <></>
                ) : (
                  <img
                    className="h-20 w-20 hover:cursor-pointer"
                    //src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                    src={icon}
                    alt="Workflow"
                  />
                )}
              </div>
              {screenWidth < 1050 && screenWidth > 765 ? (
                <></>
              ) : (
                <div
                  className="flex flex-col justify-center items-center align-center hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <div className="text-white flex items-center gap-1">
                    <span className="text-xl ml-2 font-semibold">LIFT</span>{" "}
                    <span className="text-2xl">| </span>{" "}
                    <span className="text-sm">COMPETITIONS</span>
                  </div>
                  {/* <div className="text-white flex flex-col items-center">
                    <span className="text-xl font-semibold">LIFT</span>{" "}
                    <span className="text-sm">COMPETITIONS</span>
                  </div> */}
                </div>
              )}

              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <NavLink
                    to="/"
                    className="text-gray-300 no-underline hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/competitions"
                    className="text-gray-300 no-underline hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    Competitions
                  </NavLink>
                  <NavLink
                    to="/charity"
                    className="text-gray-300 no-underline hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    Charity
                  </NavLink>
                  {/* <NavLink
                    to="/clubs"
                    className="text-gray-300 no-underline hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    Clubs
                  </NavLink> */}

                  {userIsLoggedIn ? (
                    <>
                      <NavLink
                        to="/my-account"
                        className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                      >
                        My Account {"  "}
                        <FontAwesomeIcon
                          icon={faUserAlt}
                          size="1x"
                          color="white"
                        />
                        {"  "}
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to="/sign-in"
                        className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                      >
                        Sign In
                      </NavLink>
                      <NavLink
                        to="/sign-up"
                        className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                      >
                        Sign Up
                      </NavLink>
                    </>
                  )}
                  <NavLink
                    to="/cart"
                    className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                  >
                    <FontAwesomeIcon icon={faShoppingCart} size="1x" />
                    <p className="inline ml-2">{cartItems.length}</p>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                to="/"
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Home
              </NavLink>
              <NavLink
                to="/competitions"
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Competitions
              </NavLink>
              <NavLink
                to="/charity"
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Charity
              </NavLink>
              {/* <NavLink
                to="/clubs"
                className="no-underline hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                Clubs
              </NavLink> */}

              <NavLink
                to="/about-us"
                className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                onClick={() => setIsOpen(false)}
              >
                About Us
              </NavLink>

              {userIsLoggedIn ? (
                <NavLink
                  to="/my-account"
                  className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  My Account {"  "}
                  <FontAwesomeIcon icon={faUserAlt} size="1x" color="white" />
                  {"  "}
                </NavLink>
              ) : (
                <>
                  <NavLink
                    to="/sign-in"
                    className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Sign In
                  </NavLink>
                  <NavLink
                    to="/sign-up"
                    className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Sign Up
                  </NavLink>
                </>
              )}
              <NavLink
                to="/cart"
                className="no-underline text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm lg:text-base font-medium"
                state={cartData}
                onClick={() => setIsOpen(false)}
              >
                Cart{" "}
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  className="mt-2"
                  size="lg"
                />
                <p className="inline ml-2">
                  {cartItems ? cartItems.length : "0"}
                </p>
              </NavLink>
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
}

export default NavBar;
