import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import pageNotFoundImage from "../assets/404.png";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <div className="flex justify-center">
          <img
            src={pageNotFoundImage}
            alt="page not found"
            className="w-64 sm:w-96"
          ></img>
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-center text-lg mt-3 font-semibold">
            {"  "} Oops, it looks like you're lost.
          </p>
          <button
            onClick={() => navigate("/competitions")}
            className="mb-3 text-lg hover:underline hover:font-semibold"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back to competitions
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
