import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import EntryListCard from "../components/EntryListCard";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, app } from "../firebase.config";
import Spinner from "../components/Spinner";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const EntryLists = () => {
  let x = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1,
  ];
  const [entryListsState, setEntryListsState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // You can adjust this value based on your needs

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = entryListsState.slice(indexOfFirstItem, indexOfLastItem);

  const generatePagination = (currentPage, totalPages) => {
    const delta = 1; // Number of pages before and after current page
    const range = [];

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift("...");
    }
    if (currentPage + delta < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1);
    range.push(totalPages);

    return range;
  };

  const totalPages = Math.ceil(entryListsState.length / itemsPerPage);
  const pagesToShow = generatePagination(currentPage, totalPages);

  const fetchEntryLists = async () => {
    const entryListArray = [];
    try {
      const entryListsRef = collection(db, "competitions");

      const q = query(entryListsRef, where("entryListSheetURL", "!=", null));

      const qSnap = await getDocs(q);

      qSnap.forEach((doc) => {
        entryListArray.push(doc.data());
      });

      setEntryListsState(entryListArray);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEntryLists();
  }, []);

  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          ENTRY | <span className="font-normal">LISTS</span>
        </p>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="grid place-items-center">
            <div className="grid xs:grid-cols-2 sm:grid-cols-3 grid-cols-1 gap-3">
              {currentItems.map((competition, id) => (
                <EntryListCard
                  key={id}
                  entryListSheetURL={competition.entryListSheetURL}
                  competitionTitle={competition.competitionTitle}
                  drawDate={competition.drawDate}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {totalPages > 1 ? (
        <div className="pagination">
          <button
            className="text-xs sm:text-sm"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            {<FontAwesomeIcon icon={faArrowLeft} />}
          </button>

          {pagesToShow.map((page, index) => {
            if (typeof page === "number") {
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(page)}
                  className={
                    currentPage === page ? "active text-sm" : "text-sm"
                  }
                >
                  {page}
                </button>
              );
            } else {
              return <span key={index}>...</span>;
            }
          })}

          <button
            className="text-xs sm:text-sm"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          >
            {<FontAwesomeIcon icon={faArrowRight} />}
          </button>
        </div>
      ) : (
        <></>
      )}

      <Footer />
    </div>
  );
};

export default EntryLists;
