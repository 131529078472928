import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ClubsFundraising from "../components/ClubsFundraising";
import ClubsHowItWorks from "../components/ClubsHowItWorks";
import ClubsFAQs from "../components/ClubsFAQs";

const Clubs = () => {
  const [isSelected, setIsSelected] = useState("fundraising");

  const renderComponent = () => {
    switch (isSelected) {
      case "fundraising":
        return <ClubsFundraising onComponentSelect={setIsSelected} />;
      case "howItWorks":
        return <ClubsHowItWorks />;
      case "faqs":
        return <ClubsFAQs />;
      default:
        return null;
    }
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        <div className="flex justify-center my-3">
          <div
            onClick={() => setIsSelected("fundraising")}
            className={`border-2 w-1/3 md:w-1/5 text-sm md:text-base text-center py-3 hover:cursor-pointer font-semibold shadow-sm ${
              isSelected === "fundraising"
                ? "animate duration-500 font-semibold bg-teal-400 shadow-sm text-white"
                : ""
            }`}
          >
            Fundraising
          </div>
          <div
            onClick={() => setIsSelected("howItWorks")}
            className={`border-2 w-1/3 md:w-1/5 text-sm md:text-base text-center py-3 hover:cursor-pointer font-semibold shadow-sm ${
              isSelected === "howItWorks"
                ? "animate duration-500 font-semibold bg-teal-400 shadow-sm text-white"
                : ""
            }`}
          >
            How It Works
          </div>
          <div
            onClick={() => setIsSelected("faqs")}
            className={`border-2 w-1/3 md:w-1/5 text-sm md:text-base text-center py-3 hover:cursor-pointer font-semibold shadow-sm ${
              isSelected === "faqs"
                ? "animate duration-500 font-semibold bg-teal-400 shadow-sm text-white"
                : ""
            }`}
          >
            FAQs
          </div>
        </div>

        {renderComponent()}
      </div>
      <Footer />
    </div>
  );
};

export default Clubs;
