import React from "react";
import Footer from "../components/Footer";
import ContactUsImage from "../assets/contactUs.png";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import tiktok from "../assets/tiktok.png";
import email from "../assets/email.png";

const ContactUs = () => {
  return (
    <div className="force-footer">
      <div className="main-content">
        {/* <NavBar /> */}
        <p className="text-center mt-3 font-semibold text-lg bg-white shadow-md pb-3 tracking-wider">
          CONTACT | <span className="font-normal">US</span>
        </p>
        <div className="flex flex-col justify-center items-center align-center gap-4">
          {/* Contact Us Image */}
          <div className="">
            <img src={ContactUsImage} alt="Contact Us" className="w-96" />
          </div>
          {/* Contact Us Details */}
          <div className="">
            <img src={email} alt="email" className="inline w-1/12 py-2" />{" "}
            <a
              href="mailto: info@liftcompetitions.com.au"
              className="no-underline text-black hover:font-semibold"
            >
              info@liftcompetitions.com.au
            </a>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-black hover:font-semibold"
              to="https://www.instagram.com/liftcompetitions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram" className="inline w-1/12" />{" "}
              Instagram
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-black hover:font-semibold"
              to="https://www.facebook.com/liftcompetitions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook" className="inline w-1/12" />{" "}
              Facebook
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-black hover:font-semibold"
              to="https://www.tiktok.com/@liftcompetitions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktok} alt="tiktok" className="inline w-1/12" /> Tiktok
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
